import React from 'react';
import {
	Button,
	// Col,
	//Input,
	//Label,
	//Row,
	Table,
	//Form,
	//FormGroup,
} from 'reactstrap';
import { useSelector } from 'react-redux';

const EditProfile = ({ handleEdit, handleUpdate }) => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { firstname, lastname, email, mobile, dateOfBirth, role } =
		loginAdmin || {};
	return (
		<div className="tab-pane fade show active profile_pages">
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'writeProfile'].includes(r)
			) && (
				<div className="edit_btn">
					<h5 className="f-w-600 f-16">Profile</h5>
					<i
						onClick={handleEdit}
						className="fa fa-pencil-square-o"
						style={{
							width: 'unset',
							fontSize: 18,
							padding: 11,
							color: '#e89c31',
						}}
					>
						<span>Edit</span>
					</i>
				</div>
			)}

			<div className="table-responsive profile-table profile_table">
				<Table className="table-responsive">
					<tbody>
						<tr>
							<td>First Name:</td>
							<td>
								{' '}
								<input
									className="form-control"
									type="text"
									name="firstname"
									value={firstname || ''}
									disabled
								/>{' '}
							</td>
						</tr>
						<tr>
							<td>Last Name:</td>
							<td>
								<input
									className="form-control"
									type="text"
									name="lastname"
									value={lastname || ''}
									disabled
								/>{' '}
							</td>
						</tr>
						<tr>
							<td>Email:</td>
							<td>
								{' '}
								<input
									className="form-control"
									type="text"
									name="email"
									value={email || ''}
									disabled
								/>{' '}
							</td>
						</tr>
						<tr>
							<td>Mobile Number:</td>
							<td>
								{' '}
								<input
									className="form-control"
									type="text"
									name="mobile"
									value={mobile || ''}
									disabled
								/>{' '}
							</td>
						</tr>
						<tr>
							<td>DOB:</td>
							<td>
								{' '}
								<input
									className="form-control"
									type="text"
									name="dob"
									value={dateOfBirth || ''}
									disabled
								/>
							</td>
						</tr>
						{role?.some((r) =>
							['suUsers', 'superAdmin', 'writeProfile'].includes(r)
						) && (
							<tr>
								<td></td>
								<td>
									{}
									<div className="form-button d-flex justify-content-center pt-3">
										<Button
											type="submit"
											color="primary"
											onClick={handleUpdate}
										>
											Update
										</Button>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default EditProfile;
