/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Edit, Trash2 } from 'react-feather';
import { Button, CardBody, Col, Container, Row } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import { toast } from 'react-toastify';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { useHistory } from 'react-router-dom';
import defaultproductimg from '../../assets/images/product-list/1.jpg';
import { queryString } from './../../utils/queryString';
import { useSelector, useDispatch } from 'react-redux';
import FilterProductFields from '../FilterProductComp';
import { getAllProductList, resetProducts } from '../../reducers/adminReducers';
const List_product = () => {
	const { id, userid } = queryString();
	const newId = id ? id : '';
	const serchInitialObj = {
		title: '',
		min: '',
		max: '',
		category: '',
		rarity: '',
		skip: 0,
		limit: 10,
		partnerId: newId,
	};

	const history = useHistory();

	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const {
		selectedCategory,
		productList,
		selectedRarity,
		totalProductsCount,
		loginAdmin,
	} = result || {};
	const { role } = loginAdmin || [];

	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [searchPayload, setSeachPayload] = useState(serchInitialObj);
	// const [skip,setSkip] = useState(0);
	const [hide, setHide] = useState(false);

	useEffect(() => {
		dispatch(getAllProductList(searchPayload));
	}, [dummy]);

	const handleOnDelete = async (id) => {
		try {
			setDummy(false);
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteProduct/${id}`
			);
			const { code } = response.data;
			if (code === 200) {
				setDummy(true);
				toast.success('Successfully Deleted');
			} else {
				setDummy(false);
			}
		} catch (error) {
			console.log('----------->>>', error);
		}
	};

	const onCloseModal = () => {
		setOpen(false);
	};

	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
	};

	const handleEdit = (id) => {
		setActiveId(id);
		history.push(`/products/create-product?id=${id}`);
		console.log(id, 'edit');
	};

	const handleSearchProduct = async (e) => {
		dispatch(resetProducts());
		const { value, name } = e.target;
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad[name] = value;
		copySearchPayLoad.skip = 0;
		setSeachPayload(copySearchPayLoad);
		setHide(false);
		dispatch(getAllProductList(copySearchPayLoad));
	};

	const handleLoadMore = () => {
		const newValue = searchPayload.skip + 12 + 12;
		console.log('skip', newValue);
		if (newValue >= totalProductsCount) {
			setHide(true);
		}
		const skipValue = searchPayload?.skip + 12;
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad.skip = skipValue;
		console.log('skipValue', skipValue);

		setSeachPayload(copySearchPayLoad);
		dispatch(getAllProductList(copySearchPayLoad));
	};
	const addToInventory = async (pid) => {
		const payload = {
			productId: pid,
			userId: userid,
		};
		const response = await adminInstance().post(
			'api/admin/addToInventory',
			payload
		);
		const { code, msg } = response.data;
		if (code === 200) {
			toast.success(msg);
			history.push(`/user-profile-details?id=${userid}`);
		}
	};

	return (
		<Fragment>
			<Breadcrumb title="Product List" parent="Physical" />
			<Container fluid={true}>
				<FilterProductFields
					rarityList={selectedRarity}
					handleSearchProduct={handleSearchProduct}
					categoryList={selectedCategory}
				/>

				<Row className="products-admin ratio_asos product-list-page">
					{productList &&
						productList.map((myData, i) => {
							const { name, color } = myData.rarityLevel || {};
							return (
								<Col xl="3" sm="6" key={i}>
									<div className="products-admin">
										<CardBody className="product-box">
											<div
												className="img-wrapper"
												style={{
													background: `linear-gradient(180deg, ${color}bf 50%, ${color} 100%)`,
												}}
											>
												<div className="lable-block">
													{myData.productTag ? (
														<span className="lable3">{myData.productTag}</span>
													) : (
														''
													)}
												</div>

												<div className="front product-image">
													<a href="/products/list_product" className="bg-size">
														<img
															alt=""
															className="img-fluid blur-up bg-img lazyloaded product-img"
															src={
																myData.bannerImage
																	? myData.bannerImage
																	: defaultproductimg
															}
														/>
													</a>
													<div className="product-hover">
														<ul>
															{role?.some((r) =>
																[
																	'suUsers',
																	'superAdmin',
																	'writeProduct',
																].includes(r)
															) && (
																<li>
																	<Button
																		color="btn"
																		type="button"
																		onClick={() => handleEdit(myData._id)}
																	>
																		<Edit className="editBtn" />
																	</Button>
																</li>
															)}
															{role?.some((r) =>
																[
																	'suUsers',
																	'superAdmin',
																	'delProduct',
																].includes(r)
															) && (
																<li>
																	<Button
																		color="btn"
																		type="button"
																		onClick={() => handleDelete(myData._id)}
																	>
																		<Trash2 className="deleteBtn" />
																	</Button>
																</li>
															)}
														</ul>
													</div>
												</div>
											</div>
											<div className="product-detail">
												<div className="pro_contents">
													<div className="pro_name">
														<h6>{myData.name}</h6>
													</div>
													<div className="price_legen">
														<p>{name}</p>
														<h4>
															{myData.price} DT
															<del>{myData.discount_price}</del>
														</h4>
													</div>
												</div>
												<ul className="color-variant">
													{myData.color &&
														myData.color.map((el) => {
															return (
																<li
																	className="bg-light0"
																	style={{
																		backgroundColor: el.toLowerCase(),
																	}}
																></li>
															);
														})}
												</ul>

												{userid &&
													role?.some((r) =>
														['suUsers', 'superAdmin', 'writeProduct'].includes(
															r
														)
													) && (
														<Button
															type="button"
															className="primary"
															onClick={() => addToInventory(myData._id)}
														>
															Add to Inventory
														</Button>
													)}
											</div>
										</CardBody>
									</div>
								</Col>
							);
						})}
				</Row>
				<Row>
					<Col className="d-flex justify-content-center pb-3">
						{!hide && productList.length > 0 && (
							<Button
								type="button"
								color="primary"
								className="delete-button load___more"
								style={{ background: '#ff4d53 !important' }}
								onClick={() => handleLoadMore()}
							>
								Load More
							</Button>
						)}
					</Col>
				</Row>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Product'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};

export default List_product;
