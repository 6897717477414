import React, { Fragment} from 'react'
import DataTable from 'react-data-table-component'
import 'react-toastify/dist/ReactToastify.css'
import DataTableExtensions from "react-data-table-component-extensions";
import placeholder from '../../assets/images/fashion/product/12.jpg'
import moment from 'moment';

const UserBoxTable = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
}) => {

  const columnDefs = [
    {
      name: 'Box Image',
      cell: (row) => <ProfileImage row={row} />,
    },
    {
      name: 'Box Name',
      selector: (row) => row?.boxId?.name,
      sortable: true,
    }, 
    {
      name: 'Win Item', 
      cell: (row) => <WinImage row={row} />,
      sortable: true,
    },
    {
      name: 'Win Item Name',
      selector: (row) => row?.result?.pid?.name,
      sortable: true,
    },
    {
      name: 'Win Item Price',
      selector: (row) => `${row?.result?.pid?.price.toFixed(2)} DT`,
      sortable: true,
    },
    {
      name: 'Date/Time',
      selector: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
  ]
  return (
    <div>
      <Fragment>
      <DataTableExtensions
          columns={columnDefs}
          data={myData}
          print={false}
          export={false}
        >
        <DataTable
          data={myData}
          columns={columnDefs}
          className={myClass}
          pagination
          striped={true}
          center={true}
        />
        </DataTableExtensions>
      </Fragment>
    </div>
  )
}
const ProfileImage = ({ row }) => {
  return (
    <div className='profile-image'>
      <img
        src={row.boxId ? row?.boxId?.image : placeholder}
        alt='logo'
      />
    </div>
  )
}
const WinImage = ({ row }) => {
  return (
    <div className='profile-image'>
      <img
        src={row?.result?.pid ? row?.result?.pid?.bannerImage : placeholder}
        alt='logo'
      />
    </div>
  )
}
// const ActionButton = ({ row, handleDelete, handleEdit }) => {
//   return (
//     <div className='action-btn'>
//       <span>
//         <i
//           onClick={() => handleDelete(row._id)}
//           className='fa fa-trash'
//           style={{
//             width: 35,
//             fontSize: 20,
//             padding: 11,
//             color: '#e4566e',
//           }}></i>
//       </span>
//     </div>
//   )
// }

export default UserBoxTable
