import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Breadcrumb from '../common/breadcrumb';
import ActivityListDataTable from './activityListDataTable';
import { getAllActivityLogsData } from '../../reducers/adminReducers';
import { adminInstance } from '../../config/axios';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import Loader from '../loader/loader';

const ActivityLogList = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { activityLogData } = result || {};
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [loading, setLoading] = useState(false);
	const getAllActivityLogsdata = async () => {
		setLoading(true)
		const response = await dispatch(getAllActivityLogsData())
		// console.log("Check the result of response", response?.payload)
		if (response?.payload) {
			setLoading(false)
		}
	}
	useEffect(() => {
		getAllActivityLogsdata()
		// setLoading(true);
		// dispatch(getAllActivityLogsData());
		// setLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);
	const handleOnDelete = async (id) => {
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteActivityLog/${id}`
			);
			const { code } = response.data;
			if (code === 200) {
				dispatch(getAllActivityLogsData());
				toast.success('Successfully Deleted');
			}
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<>
			<Fragment>
				<Breadcrumb title="Activity Logs" parent="Logs" />
				<Container fluid={true}>
					<div className="admin-card">
						<Card>
							<CardHeader>
								<h5>All Activity Logs </h5>
							</CardHeader>
							<CardBody>
								<div className="clearfix"></div>
								<div
									id="batchDelete"
									className="category-table user-list order-table coupon-list-delete"
								>
									{!loading ? (
										activityLogData && activityLogData.length > 0 ?
											<ActivityListDataTable
												multiSelectOption={true}
												myData={activityLogData}
												pageSize={10}
												pagination={true}
												setActiveId={setActiveId}
												setOpen={setOpen}
												class="-striped -highlight"
											/>
											: <h1>Data does not found</h1>
									) : (
										<Loader />
									)}
								</div>
							</CardBody>
						</Card>
					</div>
				</Container>
				<DeleteConfirmPopup
					title={'Delete Category'}
					open={open}
					handleOnDelete={handleOnDelete}
					onCloseModal={onCloseModal}
					id={activeId}
				/>
			</Fragment>
		</>
	);
};
export default ActivityLogList;
