/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
// import { Link } from 'react-router-dom'
//import data from "../../assets/data/listVendor";
//import Datatable from "../common/datatable";
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
// import { useSelector, useDispatch } from 'react-redux'
// import { adminInstance } from '../../config/axios'
// import { getAllRarityList } from '../../../reducers/adminReducers'
import ListDatatable from './listdatatable';
import ConfirmationPopUp from '../common/ConfirmationPopUp';
// import { toast } from 'react-toastify'
// import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { getOrders, updateOrders } from '../../reducers/adminReducers';
import { toast } from 'react-toastify';
import Loader from '../loader/loader';
// import InvoicePopUp from './invoice';
const InventoryManagement = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { allOrders, orderDetails } = result || {};
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	//   const [paymentStatus,setPaymentStatus] = useState();

	const [payload, setPayload] = useState({
		orderId: '',
		status: '',
		displayName: '',
		reason: '',
		shippedItems: '',
		total: ''
	});

	// console.log('payload', payload);

	//   const getAllRarityData = async () => {
	//     try {
	//       const response = await adminInstance().get('api/admin/getRarity')
	//       const { code, rarity } = response.data;
	//       console.log('response.data',response.data)
	//       if (code === 200) {
	//         setRarityList(rarity)
	//       }
	//     } catch (error) {

	//     }
	//    };

	const handleReason = (e) => {
		const { value, name } = e.target;
		const copy = { ...payload }
		// console.log('copyMan', copy?.status)
		// console.log("Ckeck in the body", copy?.orderId)

		if (name === 'shippedItems') {
			if (!value || (parseFloat(value) <= copy?.total)) {
				// console.log('coming in this condition')
				copy[name] = parseFloat(value);
			}
		} else {

			copy[name] = value;
		}

		setPayload(copy)

	}

	const [selected, setSelected] = useState([]);
	const [selectedlen, setSelectedLen] = useState(0);

	useEffect(() => {
		if (orderDetails?.orderSummary?.length > 0) {
			const all = [];
			orderDetails?.orderSummary?.forEach((el) => {
				if (el._id && el.status !== 'shipped') {
					all.push(el._id)
				}
			}
			);
			// console.log("Check ALL", all)
			setSelectedLen(all.length)
			setSelected(all);

			setPayload({ ...payload, shippedItems: orderDetails?.orderSummary?.length, total: orderDetails?.orderSummary?.length })
		}
	}, [orderDetails])
	// console.log("Selected the id======>", selected)
	const handleSelected = (dataId) => {
		const copy = selected.slice();
		const fIndex = copy.findIndex((el) => el === dataId);
		if (fIndex >= 0) {
			copy.splice(fIndex, 1);
			setSelectedLen(selectedlen - 1)
		} else {
			copy.push(dataId);
			setSelectedLen(selectedlen + 1)
		}

		setSelected(copy);
	};
	const getOrdersData = async () => {
		setLoading(true)
		const response = await dispatch(getOrders())
		// console.log('This is boat play ', response?.payload)
		if (response?.payload) {
			setLoading(false)
		}
	}

	useEffect(() => {
		getOrdersData();
	}, [dummy]);
	const handleConfirm = async (id) => {
		setDummy(false);
		try {
			// console.log('payload====>>', selected);
			const newPayload = { ...payload }
			newPayload.selected = selected
			setOpen(false);
			setDummy(true);
			dispatch(updateOrders(newPayload));
			dispatch(getOrders());
			toast.success('Order Status Updated Successfully', { toastId: 'select' });
		} catch (error) { }
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Withdraw Funds List" parent="Withdraw" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>Withdraw Details</h5>
						</CardHeader>
						<CardBody>
							<div className="btn-popup pull-right">
								{/* <Link to='/products/add-raritylevel' className='btn btn-secondary'>
                Withdraw Funds
              </Link> */}
							</div>
							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{/* {console.log('ayushi',RarityList)} */}
								{!loading ? (
									allOrders.length > 0 && (
										<ListDatatable
											multiSelectOption={true}
											myData={allOrders}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setPayload={setPayload}
											selectedlen={selectedlen}
											selected={selected}
										/>
									)
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<ConfirmationPopUp
				title={`Are You Sure Want to ${payload?.displayName} ?`}
				subtitle=""
				open={open}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				displayName={payload?.displayName}
				handleReason={handleReason}
				payload={payload}
				orderDetails={orderDetails}
				selected={selected}
				handleSelected={handleSelected}
				selectedlen={selectedlen}
			/>
			{/* <InvoicePopUp
				title={`Are You Sure Want to ${payload?.displayName} ?`}
				subtitle=""
				open={open}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				displayName={payload?.displayName}
				// handleReason={handleReason}
				payload={payload}
				orderDetails={orderDetails}
				selected={selected}
				handleSelected={handleSelected}
				selectedlen={selectedlen}
			/> */}
		</Fragment>
	);
};
export default InventoryManagement;
