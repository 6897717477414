import React, { Fragment, useState } from "react";
// import SearchHeader from "./searchHeader";
import UserMenu from "./user-menu";
import {
	AlignLeft,
	Maximize2,
	MoreHorizontal,
} from "react-feather";

//images
import logo from "../../../assets/images/dashboard/logo.png";

const Header = () => {
	const [sidebar, setSidebar] = useState(true);
	// const [rightSidebar, setRightSidebar] = useState(true);
	const [navMenus, setNavMenus] = useState(false);

	const toggle = () => {
		setNavMenus((prevState) => ({
			navMenus: !prevState.navMenus,
		}));
	};

	// const showRightSidebar = () => {
	// 	if (rightSidebar) {
	// 		setRightSidebar(false);
	// 		document.querySelector(".right-sidebar").classList.add("show");
	// 	} else {
	// 		setRightSidebar(true);
	// 		document.querySelector(".right-sidebar").classList.remove("show");
	// 	}
	// };
	const goFull = () => {
		if (
			(document.fullScreenElement && document.fullScreenElement !== null) ||
			(!document.mozFullScreen && !document.webkitIsFullScreen)
		) {
			if (document.documentElement.requestFullScreen) {
				document.documentElement.requestFullScreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullScreen) {
				document.documentElement.webkitRequestFullScreen(
					Element.ALLOW_KEYBOARD_INPUT
				);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	};
	const openCloseSidebar = () => {
		if (sidebar) {
			setSidebar(false);
			document.querySelector(".page-main-header").classList.add("open");
			document.querySelector(".page-sidebar").classList.add("open");
		} else {
			setSidebar(true);
			document.querySelector(".page-main-header").classList.remove("open");
			document.querySelector(".page-sidebar").classList.remove("open");
		}
	};

	return (
		<Fragment>
			{/* open */}
			<div className="page-main-header ">
				<div className="main-header-right row">
					<div className="main-header-left d-lg-none">
						<div className="logo-wrapper logo2">
							<a href="index.html">
								<img className="blur-up lazyloaded" src={logo} alt="" />
							</a>
						</div>
					</div>
					<div className="mobile-sidebar">
						<div className="media-body text-right switch-sm">
							<label className="switch">
								<a href="##" onClick={openCloseSidebar}>
									<AlignLeft />
								</a>
							</label>
						</div>
					</div>
					<div className="nav-right col">
						<ul className={"nav-menus " + (navMenus ? "open" : "")}>
							<li>
								{/* <SearchHeader /> */}
							</li>
							<li>
								<a  href="##" onClick={goFull} className="text-dark">
									<Maximize2 />
								</a>
							</li>
							{/* <li className="onhover-dropdown">
								<a className="txt-dark" href="##">
									<h6>EN</h6>
								</a>
								<Language />
							</li> */}

							{/* <li className="onhover-dropdown">
								<Bell />
								<span className="badge badge-pill badge-primary pull-right notification-badge">
									3
								</span>
								<span className="dot"></span>
								<Notification />
							</li> */}
							{/* <li>
								<a href="##">
									<MessageSquare />
									<span className="dot"></span>
								</a>
							</li> */}
							{/* <li>
								<a href="##" onClick={showRightSidebar}>
									<MessageSquare />
									<span className="dot"></span>
								</a>
							</li> */}
							<UserMenu />
						</ul>
						<div
							className="d-lg-none mobile-toggle pull-right"
							onClick={() => toggle()}
						>
							<MoreHorizontal />
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Header;
