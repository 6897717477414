import React, { Fragment, useState, useEffect } from 'react'
import { Button, Form, FormGroup, Label, Row, Col } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yupPartnerValidation } from '../../utils/authValidation'
import { adminInstance } from '../../config/axios'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'
import { validateImage } from '../../utils/multiuseFunctions';

const VendorForm = ({ payload, categories, selectedOption }) => {
  const history = useHistory()
  const [selectedList, setSelectedList] = useState([])
  const [errormsg, seterrorMsg] = useState('')
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: payload,
    resolver: yupResolver(yupPartnerValidation()),
  })
  const onSubmitHandler = async (data) => {
    const formData = new FormData()
    setSingleClick(true)
    formData.append('file', data?.logo[0])
    delete data.logo
    Object.keys(data).forEach((el) => {
      if (el === 'category') {
        formData.append(el, JSON.stringify(data[el]))
      } else {
        formData.append(el, data[el])
      }
    })
    try {
      const { _id } = payload
      if (!_id) {
        if (Object.keys(errors).length === 0) {
          if (selectedList && selectedList.length > 0) {
            seterrorMsg('')
            const response = await adminInstance().post(
              '/api/admin/createPartner',
              formData
            )
            const { code, msg } = response.data
            if (code === 200) {
              reset()
              toast.success(msg, { toastId: 1 })
              history.push('/vendors/list_vendors')
            } else {
              toast.error(msg, { toastId: 1 })
            }
          } else {
            seterrorMsg('One Should be selected')
          }
        }
      } else {
        if (Object.keys(errors).length === 0) {
          const response = await adminInstance().put(
            `/api/admin/updatePartner/${_id}`,
            formData
          )
          const { code, msg } = response.data
          if (code === 200) {
            reset()
            toast.success(msg, { toastId: 1 })
            history.push('/vendors/list_vendors')
          } else {
            toast.error(msg, { toastId: 1 })
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleChangePro = (Opt) => {
    const datas = Array.isArray(Opt) ? Opt.map((x) => x.value) : []
    setSelectedList(datas)
    console.log('datas=>', datas)
    if (datas && datas.length > 0) {
      seterrorMsg('')
    }
    setValue('category[]', datas)
  }
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState(payload.logo || '')
  const [singleClick, setSingleClick] = useState(false);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(payload.logo || '')
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile])

  const onSelectFile = async (e) => {
		try{
			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined);
				return;
			}
			await validateImage(e.target.files[0]); 
			setSelectedFile(e.target.files[0]);
		}catch(error){
		const { msg, valid} = error || {}
			if(!valid){
				toast.error(msg);
			}
		}
	};

  return (
    <Fragment>
      <Form
        className='needs-validation user-add'
        onSubmit={handleSubmit(onSubmitHandler)}>
        <Row>
          <Col xl='6'>
            {/* <h4>Account Details</h4> */}
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Name
                </Label>
                <input
                  {...register('firstname')}
                  className='form-control'
                  // defaultValue={initialValues.firstName}
                  type='text'
                />
                <p className='text-danger'>{errors.firstname?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Email
                </Label>
                <input
                  {...register('email')}
                  className='form-control'
                  // defaultValue="sdfsdf"
                  type='text'
                />
                <p className='text-danger'>{errors.email?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Logo</Label>
                <div className='file-upload'>
                  <div className='file-select'>
                    <div className='file-select-button' id='fileName'>
                      Choose File
                    </div>
                    <div className='file-select-name' id='noFile'>
                      No file chosen...
                    </div>
                    <input
                      id='chooseFile'
                      {...register('logo')}
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={onSelectFile}
                    />
                  </div>
                  {preview && (
                    <img
                      className='preview-imagebox'
                      style={{ width: '100%' }}
                      src={preview}
                      alt='as'
                    />
                  )}
                </div>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Description </Label>
                <textarea
                  rows='4'
                  cols='12'
                  {...register('description')}
                  className='form-control'
                  type='text'
                  defaultValue={payload.description || ''}
                />
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Category{' '}
                </Label>
                <Select
                  isMulti
                  defaultValue={selectedOption}
                  onChange={handleChangePro}
                  options={categories}
                  isClearable
                  name='category'
                />
                {errormsg ? (
                  <p className='text-danger'>{errormsg}</p>
                ) : (
                  <p className='text-danger'>{errors.category?.message}</p>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col xl='6'>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Address{' '}
                </Label>
                <input
                  {...register('address')}
                  className='form-control'
                  type='text'
                  required=''
                />
                <p className='text-danger'>{errors.address?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Facebook </Label>
                <input
                  {...register('facebook')}
                  className='form-control'
                  type='text'
                  required=''
                />
              </div>
            </FormGroup>
            {/* <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Twitter </Label>
                <input
                  {...register('twitter')}
                  className='form-control'
                  type='text'
                  required=''
                />
              </div>
            </FormGroup> */}
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Instagram </Label>
                <input
                  {...register('instagram')}
                  className='form-control'
                  type='text'
                  required=''
                />
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>Website </Label>
                <input
                  {...register('website')}
                  className='form-control'
                  type='text'
                  required=''
                />
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-10 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Phone Number{' '}
                </Label>
                <input
                  {...register('phoneNumber')}
                  className='form-control'
                  type='text'
                  required=''
                />
                <p className='text-danger'>{errors.phoneNumber?.message}</p>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <div className='form-button d-flex justify-content-start pt-3'>
          <Button color='primary' type='submit' disabled={singleClick}>
            Submit
          </Button>
        </div>
      </Form>
    </Fragment>
  )
}

export default VendorForm
