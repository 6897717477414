import React, { Fragment} from 'react'
import DataTable from 'react-data-table-component'
import 'react-toastify/dist/ReactToastify.css'
import DataTableExtensions from "react-data-table-component-extensions";
import placeholder from '../../assets/images/fashion/product/12.jpg'
import moment from 'moment';
import { Link } from 'react-router-dom';

const Userinventory = ({
  id,
  myData,
  myClass,
  multiSelectOption,
  pagination,
  setActiveId,
  setOpen,
}) => {
  const columnDefs = [
    {
      name: 'Product Image',
      cell: (row) => <ProfileImage row={row} />,
    },
    {
      name: 'Product Name',
      selector: (row) => row?.productId?.name,
      sortable: true,
    }, 
    {
      name: 'Price', 
      selector: (row) => `${row?.productId?.price} DT`,
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: 'Date/Time',
      selector: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
      sortable: true,
    },
    {
        name: 'Action',
        cell: (row) => (
            <ActionButton
                row={row}
                handleDelete={handleDelete}
            />
        ),
    },
  ]
    const handleDelete = (id) => {
        setActiveId(id);
        setOpen(true);
    };
  return (
    <div>
      <Fragment>
        <div className="btn-popup pull-left">
        <Link to={`products/list_product?userid=${id}`} className="btn btn-secondary">
          Add Inventory
        </Link>
        </div>
      <DataTableExtensions
          columns={columnDefs}
          data={myData}
          print={false}
          export={false}
        >
        <DataTable
          data={myData}
          columns={columnDefs}
          className={myClass}
          pagination
          striped={true}
          center={true}
        />
        </DataTableExtensions>
      </Fragment>
    </div>
  )
}
const ProfileImage = ({ row }) => {
  return (
    <div className='profile-image'>
      <img
        src={row.productId ? row?.productId?.bannerImage : placeholder}
        alt='logo'
      />
    </div>
  )
}
const ActionButton = ({ row, handleDelete }) => {
	return (
		<div className="action-btn">
			<span>
				<i
					onClick={() => handleDelete(row._id)}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>
		</div>
	);
};
export default Userinventory
