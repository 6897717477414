import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
//import placeholder from '../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')
// console.log('dateheck.....', dateheck)
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import {
	deleteAffiliateLevel,
	getAllTeamsData,
} from '../../reducers/adminReducers';
import { adminInstance } from '../../config/axios';
import BlockConfirmation from '../common/blockConfirmation';
import { toast } from 'react-toastify';
import { getAllAffiliateLevels } from '../../reducers/adminReducers';
import { deleteSelectedAffiliateLevel } from './../../reducers/adminReducers';
const AffiliateLevelListDataTable = ({ id, myData, myClass }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [data, setData] = useState();
	useEffect(() => {
		setData(myData);
	}, [myData]);
	const [selectedTeam, setSelectedTeam] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [open2, setOpen2] = useState(false);
	const [activeUser, setActiveUser] = useState({});
	const columnDefs = [
		{
			name: 'Level Name',
			selector: (row) => row?.levelName,
			sortable: true,
		},
		{
			name: 'Level Description',
			selector: (row) => row.levelDescription,
			sortable: true,
		},
		{
			name: 'Referee Commission Type',
			selector: (row) => row.refereeCommissionType,
			sortable: true,
		},
		{
			name: 'Referee Bonus',
			selector: (row) => row.refereeBonus,
			sortable: true,
		},

		{
			name: 'Referer Commission Type',
			selector: (row) => row.referrerCommissionType,
			sortable: true,
		},
		{
			name: 'Referer Commission',
			selector: (row) => row.referrerCommission,
			sortable: true,
		},


		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					handleBlock={handleBlock}
				/>
			),
		},
	];
	const handleEdit = (id) => {
		console.log("hello this is my id ", id);
		setActiveId(id);
		console.log("hello this is my id ", id);
		console.log('active id', id);

		history.push(`/addAffiliateLevel?id=${ id }`);
		console.log(id, 'edit');
	};


	const handleDelete = (id) => {
		setActiveId(id);
		// setOpenDelPopup(true);
		setOpen(true);
		console.log("hello i am here this is delete function-----> ");
		// handleDelete2();


		console.log(id, 'delete');
	};








	const handleBlock = (id, data) => {
		setActiveUser(data);
		setActiveId(id);
		setOpen2(true);
		// setBlockUnblockPopup(true);
		// setUserId(data?._id);
		// setblockUnblock(!data?.isBlock);
		// setBlockUnblockInfo({
		// 	blockInfo: data?.isBlock,
		// 	username: data?.firstname,
		// });
	};
	const handleSelectUsers = (row) => {
		let selectedTeams = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});
			setSelectedTeam(selectedTeams);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedTeams?.push(el?._id);
			});
			setSelectedTeam(selectedTeams);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		console.log("this is checked id ", id);
		await dispatch(deleteSelectedAffiliateLevel(id));
		setOpenDelPopup(false);
		setSelectedRows(false);
	};

	const onCloseModal = () => {
		setOpenDelPopup(false);
		setOpen2(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedTeam.length > 0) {
			setOpenDelPopup(true);
		}
	};
	const handleConfirm = async (id) => {
		try {
			setOpen2(false);
			const response = await adminInstance().put(`api/admin/blockTeam/${ id }`);

			const { code, block } = response.data;
			if (code === 200) {
				toast.success(`Successfully ${ block ? 'Block' : 'Unblock' }`);
				dispatch(getAllTeamsData());
			}
		} catch (error) { }
	};
	const handleOnDelete = async (id) => {

		console.log("hii this is handleOnDelete function and this id");

		try {
			setOpen(false);
			const response = await dispatch(deleteAffiliateLevel(id))
			// console.log('rea  ', response);
			const code = response.payload.resultData.data.code;
			if (code === 200) {
				toast.success('Successfully Deleted');
				dispatch(getAllAffiliateLevels());
			}
		} catch (error) { }
	};
	return (
		<div>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedTeam}
			/>
			<DeleteConfirmPopup
				title={'Delete User'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
			<div className="btn-popup pull-right">
				<span className="btn btn-secondary" onClick={selectDeleteOpen}>
					Delete
				</span>
			</div>
			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectUsers(e)}
					/>
				</DataTableExtensions>
			</Fragment>
			<BlockConfirmation
				title={'Delete User'}
				open={open2}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				blockUnblockInfo={activeUser}
			/>
		</div>
	);
};

const ActionButton = ({ row, handleDelete, handleEdit, handleBlock }) => {
	return (
		<div className="action-btn action_btn_dot">
			<Dropdown>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					<i
						className="fa fa-ellipsis-v"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: '#e4566e',
						}}
					></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>
						<div className="action client" onClick={() => handleEdit(row._id)}>
							<i
								className="fa fa-pencil"
								style={{
									fontSize: 17,
									color: '#7c7878',
									marginRight: '7px',
								}}
							></i>
							Edit
						</div>
					</Dropdown.Item>

					<Dropdown.Item>
						<div
							className="action client"
							onClick={() => handleDelete(row?._id)}
						>
							<i
								className="fa fa-trash"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#7c7878',
								}}
							></i>
							Delete
						</div>
					</Dropdown.Item>

					{/* <Dropdown.Item>
						<div
							className="action client"
							onClick={() => handleBlock(row?._id, row)}
						>
							<i
								className="fa fa-ban"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#7c7878',
								}}
							></i>
							Active/Blocked
						</div>
					</Dropdown.Item> */}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};
export default AffiliateLevelListDataTable;
