import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import ListDatatable from './listdatatable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';
const BoxList = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [BoxLists, setBoxLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [loading, setLoading] = useState(false);
	const getAllBoxessData = async () => {
		try {
			setLoading(true);
			const response = await adminInstance().get('api/admin/getBox');
			const { code, box } = response.data;
			//console.log('box==>', box);
			if (code === 200) {
				setLoading(false);
				setBoxLists(box);
			}
		} catch (error) {}
	};

	useEffect(() => {
		getAllBoxessData();
	}, [dummy]);
	const handleOnDelete = async (id) => {
		// setLoder(true);
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/deleteBox/${id}`
			);
			setDummy(true);
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted');
				// setLoder(false);
			} else {
				setDummy(false);
			}
		} catch (error) {}
	};
	const onCloseModal = () => {
		setOpen(false);
	};
	return (
		<Fragment>
			<Breadcrumb title="Box List" parent="Box" />
			<Container fluid={true}>
				<div className="admin-card">
					{/* {isLoder && <Loader /> } */}
					<Card>
						<CardHeader>
							<h5>Box Details </h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeBox'].includes(r)
							) && (
								<div className="btn-popup pull-right">
									<Link to="/create-box" className="btn btn-secondary">
										Create Box
									</Link>
								</div>
							)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									BoxLists?.length > 0 && (
										<ListDatatable
											multiSelectOption={true}
											myData={BoxLists}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setBoxLists={setBoxLists}
										/>
									)
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete Category'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
		</Fragment>
	);
};
export default BoxList;

