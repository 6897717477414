import moment from "moment-timezone";
import React, { Fragment, useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-toastify/dist/ReactToastify.css";
// import placeholder from "../../assets/images/fashion/product/12.jpg";
// import { useHistory } from "react-router-dom";
// import { Dropdown } from "react-bootstrap";
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')
// console.log('dateheck.....', dateheck)
const LoginHistory = ({
    id,
    myData,
    myClass,
    multiSelectOption,
    pagination,
    setActiveId,
    setOpen,
    setOpen2,
    setOpen3,
    setOpen4,
    setActiveUser,
}) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        setData(myData);
    }, [myData]);
    const columnDefs = [
        {
            name: "Device Name",
            selector: (row) => row?.platform,
            sortable: true,
        },
        {
            name: "OS Name",
            selector: (row) => row?.osname,
            sortable: true,
        },
        {
            name: "Device Type",
            selector: (row) => row?.deviceType,
            sortable: true,
        },
        {
            name: "Browser Name",
            selector: (row) => row?.browser,
            sortable: true,
        },
        {
            name: "IP Address ",
            selector: (row) => row?.ipAddress,
            sortable: true,
        },


        {
            name: "Date-Time",
            selector: (row) => moment(row.createdAt).format("DD MMMM YYYY HH:mm:ss"),
            sortable: true,
        },
    ];

    return (
        <div>
            <Fragment>
                <DataTableExtensions
                    columns={columnDefs}
                    data={data}
                    print={false}
                    export={false}
                >
                    <DataTable
                        data={data}
                        columns={columnDefs}
                        className={myClass}
                        pagination
                        striped={true}
                        center={true}
                    />
                </DataTableExtensions>
            </Fragment>
        </div>
    );
};

// const ActionButton = ({
//   row,
//   handleDelete,
//   handleEdit,
//   handleBlock,
//   handleAddWallet,
//   handleGeneratePassword,
//   handleUserProfile,
// }) => {
//   return (
//     <div className="action-btn action_btn_dot">
//       <Dropdown>
//         <Dropdown.Toggle variant="success" id="dropdown-basic">
//           <i
//             className="fa fa-ellipsis-v"
//             style={{
//               width: 35,
//               fontSize: 20,
//               padding: 11,
//               color: "#e4566e",
//             }}
//           ></i>
//         </Dropdown.Toggle>
//         <Dropdown.Menu>
//           <Dropdown.Item>
//             <div className="action client" onClick={() => handleEdit(row._id)}>
//               <i
//                 className="fa fa-pencil"
//                 style={{
//                   fontSize: 17,
//                   color: "rgb(40, 167, 69)",
//                   marginRight: "7px",
//                 }}
//               ></i>
//               Edit
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleDelete(row._id)}
//             >
//               <i
//                 className="fa fa-trash"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#ef072e",
//                 }}
//               ></i>
//               Delete
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleBlock(row._id, row)}
//             >
//               <i
//                 className="fa fa-ban"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#ff002b",
//                 }}
//               ></i>
//               Active/Blocked
//             </div>
//           </Dropdown.Item>
//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleAddWallet(row._id)}
//             >
//               <i
//                 className="fa fa-briefcase"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#3B7FDB",
//                 }}
//               ></i>
//               Add Wallet
//             </div>
//           </Dropdown.Item>

//           <Dropdown.Item>
//             <div
//               className="action client"
//               onClick={() => handleGeneratePassword(row._id)}
//             >
//               <i
//                 className="fa fa-unlock-alt"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#e89b32",
//                 }}
//               ></i>
//               Reset Password
//             </div>
//           </Dropdown.Item>
//           <Dropdown.Item>
//             {/* <Link to="/user-profile-details"> */}
//             <div
//               className="action client"
//               onClick={() => handleUserProfile(row._id)}
//             >
//               <i
//                 className="fa fa-user-circle-o"
//                 style={{
//                   fontSize: 17,
//                   marginRight: "7px",
//                   color: "#878787",
//                 }}
//               ></i>
//               User Profile
//             </div>
//             {/* </Link> */}
//           </Dropdown.Item>
//         </Dropdown.Menu>
//       </Dropdown>
//     </div>
//   );
// };

export default LoginHistory;
