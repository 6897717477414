import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
import placeholder from '../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import UserProfileDetails from "./user-profile-details";
// import { Link } from "react-router-dom";
// const dateheck= moment(new Date()).format('MM/DD/YYYY')

import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { deleteSelectedUsers } from '../../reducers/adminReducers';
import { adminInstance } from '../../config/axios';
import {
	toastDelPermission,
	toastEditPermission,
} from '../../utils/toastPermission';
const ListDataTable = ({
	id,
	myData,
	myClass,
	multiSelectOption,
	pagination,
	setActiveId,
	setOpen,
	setOpen2,
	setOpen3,
	setOpen4,
	setActiveUser,
	setUsersLists,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [data, setData] = useState();
	useEffect(() => {
		setData(myData);
	}, [myData]);
	const [selectedBox, setSelectedBox] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);

	let delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delUsers'].includes(r)
	);
	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeUsers'].includes(r)
	);

	const columnDefs = [
		{
			name: 'Image',
			cell: (row) => <ProfileImage row={row} />,
		},
		{
			name: 'username',
			selector: (row) => row.username,
			sortable: true,
		},
		{
			name: 'Name',
			selector: (row) => `${row.firstname || ''} ${row.lastname || ''}`,
			sortable: true,
		},
		{
			name: 'Phone',
			selector: (row) => row.mobile,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => row.createdAt,
			sortable: true,
		},
		// {
		// 	name: 'Active/Blocked',
		// 	cell: (row) => <BlockSection row={row} />,
		// 	sortable: true,
		// },
		{
			name: 'Active/Blocked',
			cell: (row) =>
				row.activeStatus === 'Active' ? (
					<span className="text-success">{row.activeStatus}</span>
				) : (
					<span className="text-danger">{row.activeStatus}</span>
				),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					handleBlock={handleBlock}
					handleAddWallet={handleAddWallet}
					handleGeneratePassword={handleGeneratePassword}
					handleUserProfile={handleUserProfile}
					role={role}
					delRole={delRole}
					editRole={editRole}
				/>
			),
		},
	];

	useEffect(() => {
		const check = delRole || editRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])

	const handleEdit = (id) => {
		setActiveId(id);
		// console.log('active id', id);
		history.push(`/users/create-user?id=${id}`);
		// console.log(id, 'edit');
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
		// console.log(id, 'delete');
	};
	const handleBlock = (id, data) => {
		setActiveUser(data);
		setActiveId(id);
		setOpen2(true);
		// setBlockUnblockPopup(true);
		// setUserId(row?._id);
		// setblockUnblock(!row?.isBlock);
		// setBlockUnblockInfo({ blockInfo: row?.isBlock, username: row?.username });
	};
	const handleAddWallet = (id) => {
		setOpen3(true);
		setActiveId(id);
	};
	const handleGeneratePassword = (id) => {
		// console.log('id', id);
		setOpen4(true);
		setActiveId(id);
	};

	const handleUserProfile = (id) => {
		// console.log('uidusodusoiduyou');
		history.push(`/user-profile-details?id=${id}`);
	};
	const handleSelectUsers = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		const res = await dispatch(deleteSelectedUsers(id));
		const { resultData } = res?.payload;
		if (resultData?.code === 200) {
			const response = await adminInstance().get('api/admin/getUserData');
			const { code, userList } = response?.data;
			if (code === 200) {
				setUsersLists(userList);
				setOpenDelPopup(false);
				setSelectedRows(false);
			}
		}
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedBox.length > 0) {
			setOpenDelPopup(true);
		}
	};
	return (
		<div>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedBox}
			/>
			{role?.some((r) => ['suUsers', 'superAdmin', 'delUsers'].includes(r)) && (
				<div className="btn-popup pull-right">
					<span className="btn btn-secondary" onClick={selectDeleteOpen}>
						Delete
					</span>
				</div>
			)}

			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectUsers(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};

const ProfileImage = ({ row }) => {
	return (
		<div className="profile-image">
			<img src={row.profile ? row.profile : placeholder} alt="logo" />
		</div>
	);
};

const ActionButton = ({
	row,
	handleDelete,
	handleEdit,
	handleBlock,
	handleAddWallet,
	handleGeneratePassword,
	handleUserProfile,
	role, delRole, editRole,
}) => {

	return (
		<div className="action-btn action_btn_dot">
			<Dropdown>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					<i
						className="fa fa-ellipsis-v"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: '#e4566e',
						}}
					></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{editRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								editRole ? handleEdit(row._id) : toastEditPermission('users')
							}
						>
							<i
								className="fa fa-pencil"
								style={{
									fontSize: 17,
									color: '#7c7878',
									marginRight: '7px',
								}}
							></i>
							Edit
						</div>
					</Dropdown.Item>}

					{delRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								delRole ? handleDelete(row._id) : toastDelPermission('users')
							}
						>
							<i
								className="fa fa-trash"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#7c7878',
								}}
							></i>
							Delete
						</div>
					</Dropdown.Item>}

					{editRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								editRole
									? handleBlock(row._id, row)
									: toast.error(`You do not have permission to block users `, {
										toastId: 'permission',
									})
							}
						>
							<i
								className="fa fa-ban"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#7c7878',
								}}
							></i>
							Active/Blocked
						</div>
					</Dropdown.Item>}
					{editRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								editRole
									? handleAddWallet(row._id)
									: toast.error(
										`You do not have permission to add user wallet`,
										{
											toastId: 'permission',
										}
									)
							}
						>
							<i
								className="fa fa-briefcase"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#7c7878',
								}}
							></i>
							Add Wallet
						</div>
					</Dropdown.Item>}

					{editRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								editRole
									? handleGeneratePassword(row._id)
									: toast.error(
										`You do not have permission to reset password`,
										{
											toastId: 'permission',
										}
									)
							}
						>
							<i
								className="fa fa-unlock-alt"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '##7c7878',
								}}
							></i>
							Reset Password
						</div>
					</Dropdown.Item>}
					{editRole && <Dropdown.Item>
						{/* <Link style={{color:"#000"}} to="/user-profile-details"> */}
						<div
							className="action client"
							onClick={() =>
								editRole
									? handleUserProfile(row._id)
									: toast.error(
										`You do not have permission to open user profile`,
										{
											toastId: 'permission',
										}
									)
							}
						>
							<i
								className="fa fa-user-circle-o"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '##7c7878',
								}}
							></i>
							User Profile
						</div>
						{/* </Link> */}
					</Dropdown.Item>}
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default ListDataTable;
