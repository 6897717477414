import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-toastify/dist/ReactToastify.css';
//import placeholder from  '../../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import placeholder from '../../assets/images/fashion/product/12.jpg';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { add3Dots } from '../../utils/queryString';
import { adminInstance } from '../../config/axios';
import { deleteSelectedPartner } from '../../reducers/adminReducers';
import {
	toastDelPermission,
	toastEditPermission,
} from '../../utils/toastPermission';
const VendordataTable = ({
	id,
	myData,
	myClass,
	multiSelectOption,
	pagination,
	setActiveId,
	setOpen,
	setVendorsLists,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [data, setData] = useState();
	const [selectedBox, setSelectedBox] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	useEffect(() => {
		setData(myData);
	}, [myData]);

	let delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delPartner'].includes(r)
	);
	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writePartner'].includes(r)

	);
	const columnDefs = [
		{
			name: 'Image',
			cell: (row) => <ProfileImage row={row} />,
		},
		{
			name: 'Name',
			selector: (row) => row.firstname,
			sortable: true,
		},
		// {
		//   name: 'Last Name',
		//   selector: (row) => row.lastname,
		//   sortable: true,
		// },
		{
			name: 'Phone',
			selector: (row) => row.phoneNumber,
			sortable: true,
		},
		{
			name: 'Email',
			selector: (row) => row.email,
			sortable: true,
		},
		{
			name: 'Description',
			selector: (row) => add3Dots(row.description, 25),
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					handleShowProducts={handleShowProducts}
					role={role}
					delRole={delRole}
					editRole={editRole}
				/>
			),
		},
	];

	// let editRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'writeOrders'].includes(r)
	// );
	// console.log('loggg', editRole)
	useEffect(() => {
		const check = delRole || editRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])

	// delRole || editRole ? columnDefs : columnDefs.pop();
	const handleEdit = (id) => {
		setActiveId(id);
		history.push(`/vendors/create-vendors?id=${id}`);
		console.log(id, 'edit');
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
	};

	const handleShowProducts = (id) => {
		setActiveId(id);
		history.push(`/vendors/productList?id=${id}`);
	};
	const handleSelectPartner = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
		}
		setSelectedRows(true);
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		const res = await dispatch(deleteSelectedPartner(id));
		const { resultData } = res?.payload;
		if (resultData?.code === 200) {
			const response = await adminInstance().get('api/admin/getPartners');
			const { code, partners } = response.data;
			if (code === 200) {
				setVendorsLists(partners);
			}
			setOpenDelPopup(false);
			setSelectedRows(false);
		}
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedBox.length > 0) {
			setOpenDelPopup(true);
		}
	};
	return (
		<div>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedBox}
			/>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delPartner'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)}

			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectPartner(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};

const ProfileImage = ({ row }) => {
	return (
		<div className="profile-image">
			<img src={row.logo ? row.logo : placeholder} alt="logo" />
		</div>
	);
};

const ActionButton = ({
	row,
	handleDelete,
	handleEdit,
	handleShowProducts,
	role, delRole, editRole
}) => {
	// console.log('role is here-->', role);
	// const delRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'delPartner'].includes(r)
	// );
	// const editRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'writePartner'].includes(r)
	// );
	const showProduct = role?.some((r) =>
		[
			'suUsers',
			'superAdmin',
			'writeProduct',
			'readProduct',
			'delProduct',
		].includes(r)
	);
	return (
		<div className="action-btn action_btn_dot">
			<Dropdown>
				<Dropdown.Toggle variant="success" id="dropdown-basic">
					<i
						className="fa fa-ellipsis-v"
						style={{
							width: 35,
							fontSize: 20,
							padding: 11,
							color: '#e4566e',
						}}
					></i>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{editRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								editRole ? handleEdit(row._id) : toastEditPermission('partner')
							}
						>
							<i
								className="fa fa-pencil"
								style={{
									fontSize: 17,
									color: 'rgb(40, 167, 69)',
									marginRight: '7px',
								}}
							></i>
							Edit
						</div>
					</Dropdown.Item>}

					{delRole && <Dropdown.Item>
						<div
							className="action client"
							onClick={() =>
								delRole ? handleDelete(row._id) : toastDelPermission('partner')
							}
						>
							<i
								className="fa fa-trash"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#ef072e',
								}}
							></i>
							Delete
						</div>
					</Dropdown.Item>}

					<Dropdown.Item>
						<div
							className="action client"
							onClick={() => (showProduct ? handleShowProducts(row._id) : '')}
						>
							<i
								className="fa fa-archive"
								style={{
									fontSize: 17,
									marginRight: '7px',
									color: '#ef072e',
								}}
							></i>
							Products
						</div>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			{/* <span>
        <i
         
          className='fa fa-trash'
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: '#e4566e'
          }}
        ></i>
      </span>
      <span>
        <i
          onClick={() => handleEdit(row._id)}
          className='fa fa-pencil'
          style={{
            width: 35,
            fontSize: 20,
            padding: 11,
            color: 'rgb(40, 167, 69)'
          }}
        ></i>
      </span> */}
		</div>
	);
};

export default VendordataTable;
