import React, { Fragment, useEffect, useState } from 'react';
import UserPanel from './user-panel';
import { Link } from 'react-router-dom';
import { MENUITEMS } from '../../../constants/menu';
import logo from '../../../assets/images/dashboard/logo.png';
import { useSelector } from 'react-redux';
const Sidebar = () => {
	const [mainmenu, setMainMenu] = useState(MENUITEMS);
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	useEffect(() => {
		const currentUrl = window.location.pathname;
		mainmenu.map((items) => {
			mainMenu.filter((Items) => {
				if (Items.path === currentUrl) setNavActive(Items);
				if (!Items.children) return false;
				Items.children.filter((subItems) => {
					if (subItems.path === currentUrl) setNavActive(subItems);
					if (!subItems.children) return false;
					subItems.children.filter((subSubItems) => {
						if (subSubItems.path === currentUrl) {
							setNavActive(subSubItems);
							return true;
						} else {
							return false;
						}
					});
					return subItems;
				});
				return Items;
			});
			return items;
		});
		return () => {
			setMainMenu(MENUITEMS);
		};
	});

	const setNavActive = (item) => {
		MENUITEMS.filter((menuItem) => {
			if (menuItem !== item) menuItem.active = false;
			if (menuItem.children && menuItem.children.includes(item))
				menuItem.active = true;
			if (menuItem.children) {
				menuItem.children.filter((submenuItems) => {
					if (submenuItems !== item) {
						submenuItems.active = false;
					}
					if (submenuItems.children) {
						submenuItems.children.map(
							(childItem) => (childItem.active = false)
						);
						if (submenuItems.children.includes(item)) {
							submenuItems.active = true;
							menuItem.active = true;
						}
					}
					return false;
				});
			}
			return false;
		});
		item.active = !item.active;
		setMainMenu(MENUITEMS);
	};
	let wareHouse = [
		'suUsers',
		'superAdmin',
		'readWarehouse',
		'writeWarehouse',
		'delWarehouse',
		'readCategory',
		'readProduct',
		'readRarityLevel',
		'readBrand',
		'readPartner',
		'writeCategory',
		'writeProduct',
		'writeAddProduct',
		'writeRarityLevel',
		'writeBrand',
		'writePartner',
		'delCategory',
		'delProduct',
		'delRarityLevel',
		'delBrand',
		'delPartner',
	];

	let settingAcc = [
		'suUsers',
		'superAdmin',
		'readSetting',
		'writSetting',
		'readOtherSettings',
		'readQuickSellPercentage',
		'readChangePassword',
		'readProfile',
		'readHideRolChance',
		'writeOtherSettings',
		'writeQuickSellPercentage',
		'writeChangePassword',
		'writeProfile',
		'writeHideRolChance',
		'readHideTryForFree',
		'writeHideTryForFree',
		'readPreferences',
		'writePreferences',
		'readPaymentOption',
		'writePaymentOption',
		'delPreferences'

	];

	let gameMenu = [
		'readBox',
		'readBotSimulator',
		'readBattle',
		'writeBox',
		'writeBotSimulator',
		'writeBattle',
		'delBox',
		'delBotSimulator',
		'delBattle',
	];

	let teamMenu = [
		// 'readTeamMembers',
		'readActivityLog',
		// 'delTeamMembers',
		'delActivityLog',
	];
	let profitMangement = [
		'readProfitManagement',
		'writeProfitManagement',
		'delProfitManagement',
		'readWithdraws',
		'readOrders',
		'readDeposits',
		'writeWithdraws',
		'writeOrders',
		'writeDeposits',
		'delWithdraws',
		'delOrders',
		'delDeposits',
	];


	// { console.log("role==>", role) }
	const mainMenu = mainmenu.map((menuItem, i) => (
		<>

			{(role?.includes('superAdmin') ||
				role?.includes('suUsers') ||
				role?.includes(`write${menuItem.access}`) ||
				menuItem.access === 'Dashboard' ||
				(role?.some((r) => wareHouse.includes(r))
					? menuItem.access === 'Warehouse'
					: '') ||
				(role?.some((r) => settingAcc.includes(r))
					? menuItem.access === 'Setting'
					: '') ||
				(role?.some((r) => gameMenu.includes(r))
					? menuItem.access === 'GameManagement'
					: '') ||
				(role?.some((r) => teamMenu.includes(r))
					? menuItem.access === 'TeamManagement'
					: '')
				||
				(role?.some((r) => profitMangement.includes(r))
					? menuItem.access === 'ProfitManagement'
					: '')
				||

				role?.includes(`read${menuItem.access}`) ||
				role?.includes(`del${menuItem.access}`)) && (
					<li className={`${menuItem.active ? 'active' : ''}`} key={i}>
						{menuItem.sidebartitle ? (
							<div className="sidebar-title">{menuItem.sidebartitle}</div>
						) : (
							''
						)}
						{menuItem.type === 'sub' ? (
							<a
								className="sidebar-header"
								href="##"
								onClick={() => setNavActive(menuItem)}
							>
								<menuItem.icon />
								<span>{menuItem.title}</span>
								<i className="fa fa-angle-right pull-right"></i>
							</a>
						) : (
							''
						)}
						{menuItem.type === 'link' ? (
							<Link
								to={`${process.env.PUBLIC_URL}${menuItem.path}`}
								className={`sidebar-header ${menuItem.active ? 'active' : ''}`}
								onClick={() => setNavActive(menuItem)}
							>
								<menuItem.icon />
								<span>{menuItem.title}</span>
								{menuItem.children ? (
									<i className="fa fa-angle-right pull-right"></i>
								) : (
									''
								)}
							</Link>
						) : (
							''
						)}
						{menuItem.children ? (
							<ul
								className={`sidebar-submenu ${menuItem.active ? 'menu-open' : ''
									}`}
								style={
									menuItem.active
										? { opacity: 1, transition: 'opacity 500ms ease-in' }
										: {}
								}
							>
								{menuItem.children.map((childrenItem, index) => (
									<>
										{(role?.includes('superAdmin') ||
											role?.includes('suUsers') ||
											role?.includes(`write${childrenItem.access}`) ||
											role?.includes(`read${childrenItem.access}`) ||
											role?.includes(`del${childrenItem.access}`)) && (
												<li
													key={index}
													className={
														childrenItem.children
															? childrenItem.active
																? 'active'
																: ''
															: ''
													}
												>
													{childrenItem.type === 'sub' ? (
														<a href="##" onClick={() => setNavActive(childrenItem)}>
															<i className="fa fa-circle"></i>
															{childrenItem.title}{' '}
															<i className="fa fa-angle-right pull-right"></i>
														</a>
													) : (
														''
													)}

													{childrenItem.type === 'link' ? (
														<Link
															to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
															className={childrenItem.active ? 'active' : ''}
															onClick={() => setNavActive(childrenItem)}
														>
															<i className="fa fa-circle"></i>
															{childrenItem.title}{' '}
														</Link>
													) : (
														''
													)}
													{childrenItem.children ? (
														<ul
															className={`sidebar-submenu ${childrenItem.active ? 'menu-open' : 'active'
																}`}
														>
															{childrenItem.children.map((childrenSubItem, key) => (
																<>
																	{
																		<li
																			className={
																				childrenSubItem.active ? 'active' : ''
																			}
																			key={key}
																		>
																			{childrenSubItem.type === 'link' ? (
																				<Link
																					to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
																					className={
																						childrenSubItem.active ? 'active' : ''
																					}
																					onClick={() =>
																						setNavActive(childrenSubItem)
																					}
																				>
																					<i className="fa fa-circle"></i>
																					{childrenSubItem.title}
																				</Link>
																			) : (
																				''
																			)}
																		</li>
																	}
																</>
															))}
														</ul>
													) : (
														''
													)}
												</li>
											)}
									</>
								))}
							</ul>
						) : (
							''
						)}
					</li>
				)}
		</>
	));

	return (
		<Fragment>
			<div className="page-sidebar">
				<div className="main-header-left d-none d-lg-block">
					<div className="logo-wrapper sanjana">
						<Link to={`${process.env.PUBLIC_URL}/dashboard`}>
							<img className="blur-up lazyloaded" src={logo} alt="" />
						</Link>
					</div>
				</div>
				<div className="sidebar custom-scrollbar">
					<UserPanel />
					<ul className="sidebar-menu">{mainMenu}</ul>
				</div>
			</div>
		</Fragment>
	);
};

export default Sidebar;
