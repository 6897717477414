import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
} from "reactstrap";
import { useSelector } from "react-redux";
const AffiliateStats = ({userData, userId}) => {

  const result = useSelector((state) => state.admin);
  const { loginAdmin } = result || {};
  const { role } = loginAdmin || [];
  let readRole = role?.some((r) =>
    ["suUsers", "superAdmin", "readDashboard"].includes(r)
  );
  return (
    <Fragment>
      <Breadcrumb title="Affiliate Stats For User" parent="users" />
      {readRole ? (
        <Container className="dashboard-page" fluid={true}>
           <Row>
           <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL Count</h6>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                    <h5 className="mb-0" style={{ color: "#000 " }}>
                       {userData?.referalCount||0}
                      </h5>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL COMMISSION</h6>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                    <h5 className="mb-0" style={{ color: "#000 " }}>
                      {userData?.referalCommission||0} DT
                      </h5>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

            <Col xl="3 xl-50" md="6">
              <Card className="card-graph order-graph sales-carousel">
                <CardHeader className="header-card">
                  <h6>TOTAL REFERAL BONUS</h6>
                </CardHeader>
                <CardBody>
                  <Media>
                    <Media body>
                    <h5 className="mb-0" style={{ color: "#000 " }}>
                       {userData?.referalBonus||0} DT
                      </h5>
                    </Media>
                  </Media>
                </CardBody>
              </Card>
            </Col>

           </Row>
        </Container>
      ) : (
        <h2>Welcome to admin inboxi</h2>
      )}
    </Fragment>
  );
};

export default AffiliateStats;
