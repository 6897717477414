import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import DataTableExtensions from 'react-data-table-component-extensions';
import placeholder from '../../assets/images/fashion/product/12.jpg';
import { useHistory } from 'react-router-dom';
import { add3Dots } from '../../utils/queryString';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { adminInstance } from '../../config/axios';
import { deleteSelectedBrand } from '../../reducers/adminReducers';
import {
	toastDelPermission,
	toastEditPermission,
} from '../../utils/toastPermission';
const ListDatatable = ({
	id,
	myData,
	myClass,
	multiSelectOption,
	pagination,
	setActiveId,
	setOpen,
	setBrandsLists,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [data, setData] = useState();
	const [selectedBox, setSelectedBox] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);
	useEffect(() => {
		setData(myData);
	}, [myData]);
	let delRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'delBrand'].includes(r)
	);
	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeBrand'].includes(r)

	);
	const columnDefs = [
		{
			name: 'Image',
			cell: (row) => <ProfileImage row={row} />,
		},
		{
			name: 'Name',
			selector: (row) => row.name,
			sortable: true,
		},
		{
			name: 'Description',
			selector: (row) => add3Dots(row?.description || '', 25),
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => moment(row.createdAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => (
				<ActionButton
					row={row}
					handleDelete={handleDelete}
					handleEdit={handleEdit}
					role={role}
					delRole={delRole}
					editRole={editRole}
				/>
			),
		},
	];

	useEffect(() => {
		const check = delRole || editRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])
	// delRole || editRole ? columnDefs : columnDefs.pop();
	const handleEdit = (id) => {
		setActiveId(id);
		history.push(`/create-brand?id=${id}`);
		console.log(id, 'edit');
	};
	const handleDelete = (id) => {
		setActiveId(id);
		setOpen(true);
		console.log(id, 'delete');
	};
	const handleSelectCategory = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		const res = await dispatch(deleteSelectedBrand(id));
		const { resultData } = res?.payload;
		console.log('result data is here in get user data->', resultData);
		if (resultData?.code === 200) {
			const response = await adminInstance().get('api/admin/getBrand');
			const { code, brand } = response.data;
			if (code === 200) {
				setBrandsLists(brand);
			}
			setOpenDelPopup(false);
			setSelectedRows(false);
		}
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedBox.length > 0) {
			setOpenDelPopup(true);
		}
	};
	return (
		<div>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedBox}
			/>
			{role?.some((r) => ['suUsers', 'superAdmin', 'delBrand'].includes(r)) && (
				<div className="btn-popup pull-right">
					<span className="btn btn-secondary" onClick={selectDeleteOpen}>
						Delete
					</span>
				</div>
			)}

			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectCategory(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};
const ProfileImage = ({ row }) => {
	return (
		<div className="profile-image">
			<img src={row.image ? row.image : placeholder} alt="logo" />
		</div>
	);
};

const ActionButton = ({ row, handleDelete, handleEdit, role, delRole, editRole }) => {
	// const delRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'delBrand'].includes(r)
	// );
	// const editRole = role?.some((r) =>
	// 	['suUsers', 'superAdmin', 'writeBrand'].includes(r)
	// );
	return (
		<div className="action-btn">
			{delRole && <span>
				<i
					onClick={() =>
						delRole ? handleDelete(row?._id) : toastDelPermission('brand')
					}
					className="fa fa-trash"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: '#e4566e',
					}}
				></i>
			</span>}
			{editRole && <span>
				<i
					onClick={() =>
						editRole ? handleEdit(row?._id) : toastEditPermission('brand')
					}
					className="fa fa-pencil"
					style={{
						width: 35,
						fontSize: 20,
						padding: 11,
						color: 'rgb(40, 167, 69)',
					}}
				></i>
			</span>}
		</div>
	);
};

export default ListDatatable;

