import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { getAllBoxesData } from '../../reducers/adminReducers';
import AddPlayBox from './addPlayBox.js';
// import { queryString } from '../../utils/queryString'
import { useSelector, useDispatch } from 'react-redux';
// import { handleBoxPrice } from '../../utils/multiuseFunctions'

const PlayBox = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	//   const { id } = queryString()
	const selectedOption = [];
	const selectedProductOption = [];
	const { pageCounts, allBoxList } = result || {};
	// console.log('allBoxList=>', allBoxList);

	const initialObj = {
		name: '',
		image: '',
		color: '',
		price: '',
		probility: '',
		specialTag: '',
		description: '',
	};
	const serchInitialObj = {
		skip: 0,
		limit: 12,
	};
	const [chooseProduct, setChooseProduct] = useState([]);
	const [payload, setPayload] = useState(initialObj);
	const [searchPayload, setSeachPayload] = useState(serchInitialObj);
	const [selectedProductList, setSelectedProductList] = useState([]);
	const [bxPrice, setBoxPrice] = useState('0.00');
	const [totalOdds, settotalOdds] = useState(0);
	//   const getSingleBoxData = async (id) => {
	//     try {
	//       const response = await adminInstance().get(
	//         `api/admin/getBoxDetailById/${id}`
	//       )
	//       const { code, boxData, boxItems, choseProducts } = response?.data
	//       const { profitMargin } = boxData || {}
	//       handleBoxPrice([boxData], profitMargin)
	//       if (code === 200) {
	//         setChooseProduct(choseProducts)
	//         const { boxOdds } = boxPriceCalculation(choseProducts)
	//         settotalOdds(boxOdds)
	//         setSelectedProductList(boxItems)
	//         setPayload(boxData)
	//       }
	//     } catch (error) {
	//       console.log('----------->aaa>>', error)
	//     }
	//   }
	const handleSearchProduct = async (e) => {
		const { value, name } = e.target;
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad[name] = value;
		copySearchPayLoad.skip = 0;
		setSeachPayload(copySearchPayLoad);
		dispatch(getAllBoxesData(copySearchPayLoad));
	};

	const pageCount = Math.ceil(pageCounts / searchPayload.limit);
	const handlePageClick = ({ selected }) => {
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad.skip = selected * searchPayload.limit;
		setSeachPayload(copySearchPayLoad);
		dispatch(getAllBoxesData(copySearchPayLoad));
	};
	const blank = {};

	useEffect(() => {
		dispatch(getAllBoxesData(searchPayload));
		// if (id) {
		//   getSingleBoxData(id)
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch(getAllBoxesData(searchPayload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const boxPriceCalculation = (daata, profit) => {
		let sum = 0;
		daata.forEach((el) => {
			if (el.productPrice && el.chance) {
				const finalProdPrice = el.addonBlance
					? parseFloat(el.productPrice) + parseFloat(el.addonBlance)
					: parseFloat(el.productPrice);
				const res = finalProdPrice * (parseFloat(el.chance) / 100) * profit;
				const finalRes = res.toFixed(2);
				sum += parseFloat(finalRes);
			}
		});
		let totalOdds = daata.reduce(
			(pre, next) =>
				parseFloat(pre || 0) + parseFloat(next.chance ? next.chance : 0),
			0
		);
		return { boxPrice: sum.toFixed(2), boxOdds: totalOdds };
	};

	const handleProduct = (val, price) => {
		console.log('value is here in ', val);
		//let chooseProductArray = [...chooseProduct];
		// let copyArray = [...selectedProductList]
		let chooseProduct = selectedProductList.slice();
		let findIndex = chooseProduct.findIndex((el) => el.val._id === val._id);
		const obj = {
			payCount: 0,
			val,
			price,
		};
		// copyArray.push(val)
		if (findIndex === -1) chooseProduct.push(obj);
		setSelectedProductList(chooseProduct);
		//setChooseProduct(obj);
	};
	const handleOnchangePro = (e, index, profit) => {
		const { name, value } = e || {};
		let copychooseProduct = [...selectedProductList];
		copychooseProduct[index][name] = parseFloat(value);
		const { boxPrice, boxOdds } = boxPriceCalculation(
			copychooseProduct,
			profit
		);
		setPayload({ ...payload, boxPrice: boxPrice });
		setBoxPrice(boxPrice);
		settotalOdds(boxOdds);
		setChooseProduct(copychooseProduct);
	};
	const handleRemove = (index) => {
		let chooseProductArray = [...chooseProduct];
		let copyArray = [...selectedProductList];
		copyArray.splice(index, 1);
		chooseProductArray.splice(index, 1);
		setChooseProduct(chooseProductArray);
		setSelectedProductList(copyArray);
	};
	return (
		<Fragment>
			<Breadcrumb title="Box" parent="Box" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className="addbox-form">
							<CardHeader>
								<h5> Select Box </h5>
							</CardHeader>
							<CardBody>
								<AddPlayBox
									payload={blank}
									selectedOption={selectedOption}
									products={selectedProductList}
									productList={allBoxList}
									handleSearchProduct={handleSearchProduct}
									selectedProductOption={selectedProductOption}
									handlePageClick={handlePageClick}
									pageCount={pageCount}
									handleProduct={handleProduct}
									handleOnchangePro={handleOnchangePro}
									chooseProduct={chooseProduct}
									handleRemove={handleRemove}
									bxPrice={bxPrice}
									totalOdds={totalOdds}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default PlayBox;
