import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import blockImage from '../../assets/images/block-user.png';
import unblockImage from '../../assets/images/lock-user.png';

const BlockConfirmation = ({
	onCloseModal,
	open,
	handleConfirm,
	blockUnblockInfo,
	id,
}) => {
	// console.log('activeUser=>', blockUnblockInfo);
	return (
		<Modal
			className="delete-modal-dialog"
			isOpen={open}
			toggle={onCloseModal}
			style={{ overlay: { opacity: 0.1 } }}
		>
			<ModalHeader
				className="delete-modal-header"
				toggle={onCloseModal}
			></ModalHeader>

			<ModalBody>
				<img
					className="blur-up lazyloaded"
					src={blockUnblockInfo?.isBlock ? unblockImage : blockImage}
					alt="delete"
				/>
				<h3>
					Are you sure you want to{' '}
					{blockUnblockInfo?.isBlock ? (
						<span className="text-success">unblock</span>
					) : (
						<span className="text-danger">block</span>
					)}{' '}
					<b>
						{blockUnblockInfo?.firstname?.charAt(0)?.toUpperCase() +
							blockUnblockInfo?.lastname?.slice(1)}
					</b>
					?
					<br />
				</h3>
			</ModalBody>
			<ModalFooter>
				<Button
					type="button"
					color="primary"
					className="delete-button"
					style={{ background: '#ff4d53 !important' }}
					onClick={() => handleConfirm(id)}
				>
					Confirm
				</Button>
				<Button
					type="button"
					color="secondary"
					className="cancel-button"
					onClick={onCloseModal}
				>
					Cancel
				</Button>
			</ModalFooter>
		</Modal>
	);
};
export default BlockConfirmation;
