import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import AddRarityForm from './addRarityForm';
import { adminInstance } from '../../../config/axios';
//import { queryString } from '../../utils/queryString'
//import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
import Loader from '../../loader/loader';

// import { useSelector } from 'react-redux'

const AddRaritylavel = () => {
	// const result = useSelector((state) => state.admin);
	// const {selectedCategory,selectePartnerList,selectebrandList  } = result || {}
	// console.log('list data====>', {selectedCategory,selectePartnerList,selectebrandList})
	const initialObj = {
		id: '',
		name: '',
		color: '',
		rarityId: [],
		// image:[],
	};
	// console.log('initaial-,', initialObj);
	const [payload, setPayload] = useState(initialObj);
	const [loading, setLoading] = useState(false);
	//   console.log('paylaod-->>>>',payload)
	function useQuery() {
		const { search } = useLocation();
		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	let query = useQuery();
	let rarityId = query.get('id');
	//   console.log('rarityId------', rarityId)

	const getSingleRarityData = async (id) => {
		//   console.log('this is called');
		try {
			setLoading(true);
			const response = await adminInstance().get(
				`api/admin/getRarityById/${id}`
			);
			const { code, rarityData } = response.data;
			//   console.log('code, rarityData----', code, rarityData)
			if (code === 200) {
				setLoading(false);
				setPayload(rarityData);
			}
		} catch (error) {
			console.log('----------->aaa>>', error);
		}
	};
	useEffect(() => {
		getSingleRarityData(rarityId);
	}, [rarityId]);

	return (
		<Fragment>
			<Breadcrumb title="Rarity" parent="Rarity" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card>
							<CardHeader>
								<h5> Add Rarity</h5>
							</CardHeader>
							<CardBody>
								{/* {console.log('rarityId---', rarityId, 'payload---', payload)} */}
								{rarityId ? (
									payload?._id &&
									(!loading ? (
										<AddRarityForm payload={payload} setPayload={setPayload} />
									) : (
										<Loader />
									))
								) : (
									<AddRarityForm payload={payload} setPayload={setPayload} />
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AddRaritylavel;
