import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Sidebar from './common/sidebar_components/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
import Header from './common/header_components/header';
import { useDispatch } from 'react-redux';
import {
	getAdminData,
	getAllCategorySelectList,
	getAllRaritySelectList,
	getAllBrandSelectList,
	getAllPartnerSelectList,
	getAllProductSelectList,
	checkValidtoken,
} from '../reducers/adminReducers';
import Loader from './loader/loader';
const App = (props) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const initialState = {
		ltr: true,
		divName: 'RTL',
	};
	const [side, setSide] = useState(initialState);
	const ChangeRtl = (divName) => {
		if (divName === 'RTL') {
			document.body.classList.add('rtl');
			setSide({ divName: 'LTR' });
		} else {
			document.body.classList.remove('rtl');
			setSide({ divName: 'RTL' });
		}
	};
	useEffect(() => {
		if (localStorage.getItem('ad@#t')) {
			dispatch(checkValidtoken());
			dispatch(getAdminData());
			dispatch(getAllCategorySelectList());
			dispatch(getAllRaritySelectList());
			dispatch(getAllProductSelectList());
			dispatch(getAllBrandSelectList());
			dispatch(getAllPartnerSelectList());
		}
	}, [dispatch]);
	useEffect(() => {
		setLoading(true);
		// console.log("Check the role on click ===>>",props?.newRole)
		if (props?.newRole?.length > 0) {

			setLoading(false);
		}
	}, [props]);

	return (
		<>
			{localStorage.getItem('ad@#t') ? (
				<div>
					{loading ? (
						<Loader />
					) : (
						<div className="page-wrapper">
							<Header />
							<div className="page-body-wrapper">
								<Sidebar />
								<RightSidebar />
								<div className="page-body">{props.children}</div>
								<Footer />
							</div>
						</div>
					)}
					<div
						className="btn-light custom-theme"
						onClick={() => ChangeRtl(side.divName)}
					>
						{side.divName}
					</div>
				</div>
			) : (
				<Redirect to="/" />
			)}
		</>
	);
};
export default App;
