import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import { toast } from 'react-toastify';
import ListDataTable from './listDataTable';
import DeleteConfirmPopup from '../common/deleteConfirmPopup';
import '../../assets/styles/admin.css';
import BlockConfirmation from '../common/blockConfirmation';
import AddWalletAmount from '../common/addWalletAmount';
import { generatePassword } from '../../utils/multiuseFunctions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Loader from '../loader/loader';

const List_user = () => {
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { role } = loginAdmin || [];
	const [usersLists, setUsersLists] = useState([]);
	const [open, setOpen] = useState(false);
	const [activeId, setActiveId] = useState();
	const [dummy, setDummy] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [open3, setOpen3] = useState(false);
	const [open4, setOpen4] = useState(false);
	const [activeUser, setActiveUser] = useState({});
	const [walletAmount, setWalletAmount] = useState(0);
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);
	const getAllUsersData = async () => {
		try {
			setLoading(true);
			const response = await adminInstance().get('api/admin/getUserData');
			const { code, userList } = response.data;
			userList.forEach((object) => {
				object.activeStatus = object.isBlock ? 'Blocked' : 'Active';
				object.createdAt = moment(object.joined_at).format('DD/MM/YYYY HH:mm');
			});
			if (code === 200) {
				setLoading(false);
				setUsersLists(userList);
			}
		} catch (error) {
			console.log('----------->>>', error);
		}
	};
	useEffect(() => {
		getAllUsersData();
	}, [dummy]);

	const handleOnDelete = async (id) => {
		setDummy(false);
		try {
			setOpen(false);
			const response = await adminInstance().delete(
				`api/admin/removeUser/${id}`
			);
			setDummy(true);
			const { code } = response.data;
			if (code === 200) {
				toast.success('Successfully Deleted');
			} else {
				setDummy(false);
			}
		} catch (error) { }
	};
	const handleConfirm = async (id) => {
		setDummy(false);
		try {
			setOpen2(false);
			const response = await adminInstance().delete(
				`api/admin/blockUser/${id}`
			);
			setDummy(true);
			const { code, block } = response.data;
			if (code === 200) {
				toast.success(`Successfully ${block ? 'Block' : 'Unblock'}`);
			} else {
				setDummy(false);
			}
		} catch (error) { }
	};

	const handleAddWalletAmount = async (id) => {
		try {
			setOpen3(false);
			// console.log('hitting this with',id)
			const payload = {
				id,
				walletAmount,
			};
			const response = await adminInstance().put(
				'api/admin/addWalletAmount',
				payload
			);
			const { msg, code } = response?.data;
			if (code === 200) {
				toast.success(msg);
			} else {
				toast.error(msg);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const handleGeneratePassword = (id) => {
		const genPassWord = generatePassword();
		// console.log('gennnnnnnn',genPassWord,id)
		setPassword(genPassWord);
	};

	const sentUpdatedPassword = async (type) => {
		if (type === 'close') {
			return onCloseModal();
		}

		// console.log('activeId=====>>>>',activeId,password);

		if (!password) {
			return toast.error('Please Geneate Password First');
		}
		const payload = {
			userid: activeId,
			password: password,
		};
		const response = await adminInstance().put(
			'api/admin/updateUserPassword',
			payload
		);
		// console.log('response', response);
		const { msg, code } = response?.data;
		if (code === 200) {
			toast.success(msg);
			setPassword('');
		} else {
			toast.error(msg);
		}
		setOpen4(false);
	};

	const onCloseModal = () => {
		setOpen(false);
		setOpen2(false);
		setOpen3(false);
		setOpen4(false);
	};

	return (
		<Fragment>
			<Breadcrumb title="User List" parent="User" />
			<Container fluid={true}>
				<div className="admin-card">
					<Card>
						<CardHeader>
							<h5>User Details</h5>
						</CardHeader>
						<CardBody>
							{role?.some((r) =>
								['suUsers', 'superAdmin', 'writeUsers'].includes(r)
							) && (
									<div className="btn-popup pull-right">
										<Link to="/users/create-user" className="btn btn-secondary">
											Create User
										</Link>
									</div>
								)}

							<div className="clearfix"></div>
							<div
								id="batchDelete"
								className="category-table user-list order-table coupon-list-delete"
							>
								{!loading ? (
									usersLists.length > 0 && (
										<ListDataTable
											multiSelectOption={true}
											myData={usersLists}
											pageSize={10}
											pagination={true}
											class="-striped -highlight"
											setActiveId={setActiveId}
											setOpen={setOpen}
											setOpen2={setOpen2}
											setOpen3={setOpen3}
											setOpen4={setOpen4}
											setActiveUser={setActiveUser}
											setUsersLists={setUsersLists}
										/>
									)
								) : (
									<Loader />
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</Container>
			<DeleteConfirmPopup
				title={'Delete User'}
				open={open}
				handleOnDelete={handleOnDelete}
				onCloseModal={onCloseModal}
				id={activeId}
			/>
			<BlockConfirmation
				title={'Delete User'}
				open={open2}
				handleConfirm={handleConfirm}
				onCloseModal={onCloseModal}
				id={activeId}
				blockUnblockInfo={activeUser}
			/>
			<AddWalletAmount
				open={open3}
				handleUpdate={handleAddWalletAmount}
				onCloseModal={onCloseModal}
				id={activeId}
				showState={walletAmount}
				setState={setWalletAmount}
				title="Add Wallet Amount"
			/>
			<AddWalletAmount
				open={open4}
				handleUpdate={handleGeneratePassword}
				onCloseModal={sentUpdatedPassword}
				id={activeId}
				showState={password}
				title="Generate New Password"
				setState={setPassword}
			/>
		</Fragment>
	);
};
export default List_user;
