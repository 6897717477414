import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../common/breadcrumb';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import {
	getAllCategoryList,
	getSearchProducts,
} from '../../reducers/adminReducers';

import AddBoxForm from './addBoxForm.js';
import { queryString } from '../../utils/queryString';
import { adminInstance } from '../../config/axios';
import { useSelector, useDispatch } from 'react-redux';
import { boxPriceCalculation } from '../../utils/multiuseFunctions';

const AddBox = () => {
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);

	const { id } = queryString();
	const selectedOption = [];
	const selectedProductOption = [];
	const {
		selectedCategory,
		productList,
		selectedRarity,
		pageCounts,
		selectedBoxCategory,
		selectebrandList,
		selectePartnerList,
		allspecialTagList,
		boxLatestColor,
		adminSetting,
	} = result || {};
	const { boxProfit: profitMarginBox } = adminSetting || {};
	const initialObj = {
		name: '',
		image: '',
		color: '',
		price: '',
		probility: '',
		specialTag: '',
		description: '',
		profitMargin: '',
		category: [],
		setCategory: [],
		hideBox: true,
		boxQuantity: '',
		boxTimer: '',
		freeBox: false,
		boxBattle: true,
		boxProfit: '',
		enableBox: false,
		// boxProfitMargin: '',
	};
	const serchInitialObj = {
		title: '',
		min: '',
		max: '',
		category: '',
		rarity: '',
		brandId: '',
		partnerId: '',
		skip: 0,
		limit: 12,
	};
	const [chooseProduct, setChooseProduct] = useState([]);
	const [payload, setPayload] = useState(initialObj);
	const [searchPayload, setSeachPayload] = useState(serchInitialObj);
	const [selectedProductList, setSelectedProductList] = useState([]);
	const [bxPrice, setBoxPrice] = useState('0.00');
	const [totalOdds, settotalOdds] = useState(0);
	const [remainOdds, setRemainOdds] = useState(0);
	const [allTagList, setAllTagList] = useState([]);
	const [showTag, setShowTag] = useState(false);
	const [showColor,setShowColor]=useState(false)
	const [cerrors, setCerrors] = useState('');
	const [boxProfit, setBoxProfit] = useState();
	const [allLatestBoxColor,setAllLatestBoxColor] =useState([])
	//const [defaultProfitMargin, setDefaultProfitMargin] = useState('');
	const limit = 12;
	useEffect(() => {
		if (profitMarginBox) {
			setPayload({ ...payload, boxProfit: profitMarginBox });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profitMarginBox]);
	const getSingleBoxData = async (id) => {
		try {
			const response = await adminInstance().get(
				`api/admin/getBoxDetailById/${id}`
			);
			const { code, boxData, boxItems, choseProducts } = response.data;
			const { category, profitMargin } = boxData || {};
			setPayload({ ...payload, boxProfit: profitMargin });
			if (code === 200) {
				setBoxProfit(profitMargin);
				let filter1 = selectedBoxCategory.filter((item) =>
					category.includes(item.value)
				);
				const filtered = boxItems.filter(function (el) {
					return el != null;
				});
				let copyPayload = boxData;
				copyPayload.setCategory = filter1;
				setChooseProduct(choseProducts);
				const { boxOdds, remainingOdds, boxPrice } = boxPriceCalculation(
					choseProducts,
					profitMargin
				);
				copyPayload.boxPrice = boxPrice;
				settotalOdds(boxOdds);
				setRemainOdds(remainingOdds);
				setSelectedProductList(filtered);
				setPayload(copyPayload);
			}
		} catch (error) {
			// console.log('----------->aaa>>', error)
		}
	};
	const handleSearchProduct = async (e) => {
		const { value, name } = e.target;
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad[name] = value;
		copySearchPayLoad.skip = 0;
		// console.log('copySearchPayload',copySearchPayLoad)
		setSeachPayload(copySearchPayLoad);
		dispatch(getSearchProducts(copySearchPayLoad));
	};

	const pageCount = Math.ceil(pageCounts / limit);
	const handlePageClick = ({ selected }) => {
		const copySearchPayLoad = { ...searchPayload };
		copySearchPayLoad.skip = selected * limit;
		setSeachPayload(copySearchPayLoad);
		dispatch(getSearchProducts(copySearchPayLoad));
	};
	const getPreviousBox = async () => {
		try {
			const response = await adminInstance().get('api/admin/getPreviousBox');
			const { getPrevious } = response.data;
			const { specialTag, color } = getPrevious || {};
			let copyPayLoad = { ...payload };
			copyPayLoad.specialTag = specialTag;
			copyPayLoad.color = color;
			setPayload(copyPayLoad);
		} catch (error) {}
	};
	useEffect(() => {
		dispatch(getAllCategoryList());
		if (id) {
			getSingleBoxData(id);
		} else {
			setBoxProfit(adminSetting?.boxProfit);
			getPreviousBox();
		}
		// const [boxProfit, setBoxProfit] = useState();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [adminSetting]);

	useEffect(() => {
		dispatch(getSearchProducts(searchPayload));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setAllTagList(allspecialTagList);
	}, [allspecialTagList]);
    useEffect(()=>{
      setAllLatestBoxColor(boxLatestColor)
	},[boxLatestColor])
	const handleProduct = (val, id) => {
		let chooseProductArray = [...chooseProduct];
		let copyArray = [...selectedProductList];
		const obj = {
			chance: 0,
			pid: id,
			addonBlance: 0,
			productPrice: val?.price,
		};
		copyArray.push(val);
		chooseProductArray.push(obj);

		chooseProductArray.sort(
			(a, b) => parseFloat(b.productPrice) - parseFloat(a.productPrice)
		);
		copyArray.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
		//console.log('okkkk=>', {copyArray,chooseProductArray})
		setSelectedProductList(copyArray);
		setChooseProduct(chooseProductArray);
	};

	const handleOnchangePro = (e, index) => {
		const { name, value } = e || {};
		let copychooseProduct = [...chooseProduct];
		copychooseProduct[index][name] = value;
		///parseFloat(value)
		const { boxPrice, boxOdds, remainingOdds } = boxPriceCalculation(
			copychooseProduct,
			boxProfit
		);
		setPayload({ ...payload, boxPrice: boxPrice });
		setBoxPrice(boxPrice);
		settotalOdds(boxOdds);
		setRemainOdds(remainingOdds);
		setChooseProduct(copychooseProduct);
	};
	const handleRemove = (index) => {
		let chooseProductArray = [...chooseProduct];
		let copyArray = [...selectedProductList];
		chooseProductArray.splice(index, 1);
		copyArray.splice(index, 1);
		const { boxPrice, boxOdds, remainingOdds } = boxPriceCalculation(
			chooseProductArray,
			boxProfit
		);
		//console.log({boxPrice, boxOdds, remainingOdds })
		setPayload({ ...payload, boxPrice: boxPrice });
		setBoxPrice(boxPrice);
		settotalOdds(boxOdds);
		setRemainOdds(remainingOdds);
		setSelectedProductList(copyArray);
		setChooseProduct(chooseProductArray);
	};
	const handleSpecialTag = (value, type) => {
		if (type === 'select') {
			setShowTag(false);
			setPayload({ ...payload, specialTag: value });
		} else {
			setShowTag(true);
			setPayload({ ...payload, specialTag: value });
			const taglist =
				allspecialTagList &&
				allspecialTagList.filter(function (val) {
					return val.includes(value);
				});
			setAllTagList(taglist);
		}
	};
	const handleSelectChange = (Opt, name) => {
		let newPayload = { ...payload };
		const datas = Array.isArray(Opt) ? Opt.map((x) => x.value) : [];
		newPayload[name] = datas;
		setPayload(newPayload);
		if (datas.length > 0) {
			setCerrors('');
		} else {
			setCerrors('This field is required');
		}
	};
	const handleOnChangeVisibility = (e, p) => {
		const { name } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = p;
		setPayload(newPayload);
		// new
	};
	const handleBoxQuantity = (e) => {
		const { name, value } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = value;
		setPayload(newPayload);
	};
	const handleBoxTimer = (e) => {
		const { name, value } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = value;
		setPayload(newPayload);
	};
	const handleOnChangeFreeBox = (e, p) => {
		const { name } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = p;
		setPayload(newPayload);
	};
	const handleBoxBattle = (e, p) => {
		const { name } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = p;
		setPayload(newPayload);
	};
	const handleProfitMorgin = (e) => {
		const { name, value } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = value;
		setPayload(newPayload);
	};
	const handleOnChangeVisible = (e, p) => {
		const { name } = e.target;
		let newPayload = { ...payload };
		newPayload[name] = p;
		setPayload(newPayload);
	};
	const handleLatestBoxColor = (value, type) => {
		
		if (type === 'select') {
			setShowColor(false);
			setPayload({ ...payload, color: value });
		} else {
			setShowColor(true);
			setPayload({ ...payload, color: value });
			const latestColor =
			boxLatestColor &&
			boxLatestColor.filter(function (val) {
					return val.includes(value);
				});
			setAllLatestBoxColor(latestColor);
		}
	};

	return (
		<Fragment>
			<Breadcrumb title="Box" parent="Box" />
			<Container fluid={true}>
				<Row>
					<Col sm="12">
						<Card className="addbox-form">
							<CardHeader>
								<h5> Add Box </h5>
							</CardHeader>
							<CardBody>
								{id ? (
									payload?._id && (
										<AddBoxForm
											payload={payload}
											selectedOption={selectedOption}
											products={selectedProductList}
											productList={productList}
											categoryList={selectedCategory}
											handleSearchProduct={handleSearchProduct}
											selectedProductOption={selectedProductOption}
											rarityList={selectedRarity}
											handlePageClick={handlePageClick}
											pageCount={pageCount}
											handleProduct={handleProduct}
											handleOnchangePro={handleOnchangePro}
											chooseProduct={chooseProduct}
											handleRemove={handleRemove}
											bxPrice={bxPrice}
											boxCategoryList={selectedBoxCategory}
											totalOdds={totalOdds}
											selectebrandList={selectebrandList}
											selectePartnerList={selectePartnerList}
											boxProfit={boxProfit}
											remainOdds={remainOdds}
											allspecialTagList={allTagList}
											boxLatestColor={allLatestBoxColor}
											handleSpecialTag={handleSpecialTag}
											showTag={showTag}
											handleSelectChange={handleSelectChange}
											setCerrors={setCerrors}
											cerrors={cerrors}
											handleOnChangeVisibility={handleOnChangeVisibility}
											handleBoxQuantity={handleBoxQuantity}
											handleBoxTimer={handleBoxTimer}
											handleOnChangeFreeBox={handleOnChangeFreeBox}
											handleBoxBattle={handleBoxBattle}
											handleProfitMorgin={handleProfitMorgin}
											handleOnChangeVisible={handleOnChangeVisible}
											handleLatestBoxColor={handleLatestBoxColor}
											showColor={showColor}
										/>
									)
								) : (
									<>
										{payload?.color && (
											<AddBoxForm
												payload={payload}
												selectedOption={selectedOption}
												products={selectedProductList}
												productList={productList}
												categoryList={selectedCategory}
												handleSearchProduct={handleSearchProduct}
												selectedProductOption={selectedProductOption}
												rarityList={selectedRarity}
												handlePageClick={handlePageClick}
												pageCount={pageCount}
												handleProduct={handleProduct}
												handleOnchangePro={handleOnchangePro}
												chooseProduct={chooseProduct}
												handleRemove={handleRemove}
												bxPrice={bxPrice}
												boxCategoryList={selectedBoxCategory}
												totalOdds={totalOdds}
												selectebrandList={selectebrandList}
												selectePartnerList={selectePartnerList}
												boxProfit={boxProfit}
												remainOdds={remainOdds}
												allspecialTagList={allTagList}
												boxLatestColor={allLatestBoxColor}
												handleSpecialTag={handleSpecialTag}
												showTag={showTag}
												handleSelectChange={handleSelectChange}
												setCerrors={setCerrors}
												cerrors={cerrors}
												handleOnChangeVisibility={handleOnChangeVisibility}
												handleBoxQuantity={handleBoxQuantity}
												handleBoxTimer={handleBoxTimer}
												handleOnChangeFreeBox={handleOnChangeFreeBox}
												handleBoxBattle={handleBoxBattle}
												handleProfitMorgin={handleProfitMorgin}
												handleOnChangeVisible={handleOnChangeVisible}
												handleLatestBoxColor={handleLatestBoxColor}
												showColor={showColor}
												// defaultProfitMargin={defaultProfitMargin}
												// onChange={onChange}
												// value={value}
											/>
										)}
									</>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default AddBox;
