import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import AddCategoryForm from "./addCategoryForm";
import { adminInstance } from "../../config/axios";
import { useLocation } from "react-router-dom";
import Loader from "../loader/loader";
const AddCategory = () => {
  const initialObj = {
    name: "",
    image: "",
    description: "",
    categoryType: "product",
    dontShowInStore: false,
  };

  const [payload, setPayload] = useState(initialObj);
  const [loading, setLoading] = useState(false);
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();
  let categoryId = query.get("id");

  const getSingleCategoryData = async (id) => {
    try {
      setLoading(true);
      const response = await adminInstance().get(
        `api/admin/getCategoryDetailById/${id}`
      );
      const { code, categoryData } = response.data;
      if (code === 200) {
        setLoading(false);
        setPayload(categoryData);
      }
    } catch (error) {
      console.log("----------->aaa>>", error);
    }
  };
  useEffect(() => {
    getSingleCategoryData(categoryId);
  }, [categoryId]);

  return (
    <Fragment>
      <Breadcrumb title='Category' parent='Category' />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <Card>
              <CardHeader>
                <h5> Add Category </h5>
              </CardHeader>
              <CardBody>
                {categoryId ? (
                  !loading ? (
                    payload?._id && <AddCategoryForm payload={payload} />
                  ) : (
                    <Loader />
                  )
                ) : (
                  <AddCategoryForm payload={initialObj} />
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddCategory;
