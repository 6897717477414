import React, { Fragment, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import 'react-toastify/dist/ReactToastify.css';
import DataTableExtensions from 'react-data-table-component-extensions';
// import placeholder from '../../assets/images/fashion/product/12.jpg';
// import { useHistory } from 'react-router-dom'
// import { add3Dots } from '../../utils/queryString';
import moment from 'moment';
// import { toast } from 'react-toastify';
// import { handleBoxPrice } from '../../utils/multiuseFunctions';
import { useSelector, useDispatch } from 'react-redux';
// import { getOrders, updateOrders } from '../../reducers/adminReducers';
import DeleteConfirmForSelected from '../common/deleteConfirmForSelected';
import { deleteSelectedOrders, getOrderDetailsByOrderId, getOrders } from '../../reducers/adminReducers';
import { Dropdown, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactExport from 'react-export-excel';
// import { toast } from 'react-toastify';
// import ReactDOMServer from "react-dom/server";
// import { jsPDF } from "jspdf";

const ListDatatable = ({ id, myData, myClass, multiSelectOption, pagination, setActiveId, setOpen, setPayload, selectedlen, selected }) => {
	// console.log("Check  mMYDATA +++++++++>>>>>>", myData)
	const dispatch = useDispatch();
	const [data, setData] = useState();
	const result = useSelector((state) => state.admin);
	const { loginAdmin, adminSetting } = result || {};
	const { role } = loginAdmin || [];
	const { boxProfit } = adminSetting || {};
	const [selectedBox, setSelectedBox] = useState([]);
	const [openDelPopup, setOpenDelPopup] = useState(false);
	const [selectedRows, setSelectedRows] = useState(true);

	const { ExcelFile } = ReactExport;
	const { ExcelSheet } = ReactExport.ExcelFile;
	const { ExcelColumn } = ReactExport.ExcelFile;
	const [dummyData, setdummyData] = useState();

	const handleStatusChange = (statusType, rowId, displayName, rowitems) => {
		// console.log('hello');
		// const fIndex  = data.findIndex((el)=>el._id===rowId)
		// console.log('fIndex',fIndex);
		// const copy = [...data]
		// copy[fIndex].status = e.target.value 
		// setData(copy)
		// console.log('orderId', rowId)
		if (displayName === 'process') {
			dispatch(getOrderDetailsByOrderId(rowId))
		}
		setOpen(true)
		setPayload({
			orderId: rowId,
			status: statusType,
			displayName: displayName,
			shippedItems: rowitems || '',
			total: rowitems || ''
		})
	}
	useEffect(() => {
		setData(myData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [myData, boxProfit]);
	useEffect(() => {
		let serverUsers = myData?.map((u, i) => ({
			'Order Number': u?.orderid,
			'Customer Name': u?.userId?.username,
			'Address': u?.shippingAddress?.address,
			'Order Quantity': u?.totalItems,
			// 'Ordered Value': {u?.orderBy===''u?.totalAmount},
			'Ordered Value': `${u?.totalAmount}`,
			'Shipping Method': u?.deliveryMethod || '',
			'Date/Time': moment(u.createdAt).format('DD/MM/YYYY HH:mm'),
		}));
		setdummyData(serverUsers);
	}, [myData]);

	// const saveDiv2 = (divId, title) => {
	// 	var doc = new jsPDF();
	// 	console.log('okkkkkk')
	// 	const htmls = `<h1>sanajjananana pdf testststs</h1>`
	// 	doc.html(htmls);
	// 	doc.save("a4.pdf");
	// 	// doc.fromHTML(`<html><head><title>${title}</title></head><body>/<h1>okkkkkk</h1></body></html>`);
	// 	// doc.save('div.pdf');
	// }
	// console.log("Data====", data)
	const saveDiv = () => {
		let printContents = document.querySelector('.api-doc-content').innerHTML;
		let originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		window.print();
		document.body.innerHTML = originalContents;
	}

	const columnDefs = [
		{
			name: 'Order Id',
			selector: (row) => row?.orderid,
			sortable: true,
		},

		{
			name: 'Order By',
			selector: (row) => row?.userId?.username,
			sortable: true,
		},

		{
			name: 'Order From',
			selector: (row) => row?.orderBy,
			sortable: true,
		},

		{
			name: 'Total Amount',
			selector: (row) => row?.totalAmount?.toFixed(2),
			sortable: true,
		},

		{
			name: 'Order Status',
			selector: (row) => row?.status,
			sortable: true,
		},
		{
			name: 'Date/Time',
			selector: (row) => moment(row?.createdAt).format('DD/MM/YYYY HH:mm'),
			sortable: true,
		},
		{
			name: 'Action',
			cell: (row) => <ShipmentStatus row={row} handleStatusChange={handleStatusChange} downloadInvoice={downloadInvoice}
				selectedlen={selectedlen} selected={selected} saveDiv={saveDiv} role={role} />,
		}

	];

	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeOrders'].includes(r)
	);
	// console.log('loggg', editRole)

	useEffect(() => {
		const check = editRole;
		if (!check) {
			columnDefs.pop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [columnDefs])

	const handleSelectOrders = (row) => {
		let selectedPro = [];
		if (!row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
		if (row.allSelected) {
			row?.selectedRows.forEach((el) => {
				selectedPro?.push(el?._id);
			});
			setSelectedBox(selectedPro);
			setSelectedRows(true);
		}
	};
	const handleSelectedDelete = async (e, id) => {
		e.preventDefault();
		const res = await dispatch(deleteSelectedOrders(id));
		const { resultData } = res?.payload;
		if (resultData?.code === 200) {
			dispatch(getOrders());
			setOpenDelPopup(false);
			setSelectedRows(false);
		}
	};
	const onCloseModal = () => {
		setOpenDelPopup(false);
	};
	const selectDeleteOpen = (e) => {
		e.preventDefault();
		if (selectedBox.length > 0) {
			setOpenDelPopup(true);
		}
	};



	// const printDiv = (divId, title) => {
	// 	let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');
	// 	mywindow.document.write(`<html><head><title>${title}</title>`);
	// 	mywindow.document.write('</head><body >');
	// 	mywindow.document.write(document.getElementById(divId).innerHTML);
	// 	mywindow.document.write('</body></html>');

	// 	mywindow.document.close(); // necessary for IE >= 10
	// 	mywindow.focus(); // necessary for IE >= 10*/

	// 	mywindow.print();
	// 	mywindow.close();

	// 	return true;
	// }

	const downloadInvoice = () => {

	}
	let downloadFilename =
		'order-' + moment(new Date()).format('DD/MM/YYYY HH:mm');

	// const printFunc = () => {
	// 	window.print();
	// };
	return (
		<div>
			<div className="api-doc-content">
				<h1>ppppppp</h1>
			</div>
			<DeleteConfirmForSelected
				open={openDelPopup}
				handleSelectedDelete={handleSelectedDelete}
				onCloseModal={onCloseModal}
				id={selectedBox}
			/>
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delOrders'].includes(r)
			) && (
					<div className="btn-popup pull-right">
						<span className="btn btn-secondary mt-2	mr-4" onClick={selectDeleteOpen}>
							Delete
						</span>
					</div>
				)}
			{role?.some((r) =>
				['suUsers', 'superAdmin', 'delOrders'].includes(r)
			) && (
					<ExcelFile
						filename={downloadFilename}
						element={
							<Button className="btn mt-2 btn-dwnld">Download in Excel</Button>
						}
					>
						<ExcelSheet data={dummyData} name="Employees">
							<ExcelColumn label="Order Number" value="Order Number" />
							<ExcelColumn label="Customer Name" value="Customer Name" />
							<ExcelColumn label="Address" value="Address" />
							<ExcelColumn label="Order Quantity" value="Order Quantity" />
							<ExcelColumn label="Ordered Value" value="Ordered Value" />
							<ExcelColumn label="Shipping Method" value="Shipping Method" />
							<ExcelColumn label="Date/Time" value="Date/Time" />
						</ExcelSheet>
					</ExcelFile>
				)}

			<Fragment>
				<DataTableExtensions
					columns={columnDefs}
					data={data}
					print={false}
					export={false}
				>
					<DataTable
						data={data}
						columns={columnDefs}
						className={myClass}
						pagination
						striped={true}
						center={true}
						selectableRows
						clearSelectedRows={selectedRows}
						onSelectedRowsChange={(e) => handleSelectOrders(e)}
					/>
				</DataTableExtensions>
			</Fragment>
		</div>
	);
};


const ShipmentStatus = ({ row, handleStatusChange, downloadInvoice, selectedlen, selected, saveDiv, role }) => {
	let editRole = role?.some((r) =>
		['suUsers', 'superAdmin', 'writeOrders'].includes(r)
	);
	// console.log("EDIT THE PERMINSSION ==>", editRole)
	// const isAllShiped = (arr) => {
	// 	if (!Array.isArray(arr) || arr.length === 0) {
	// 		return true;
	// 	}

	// 	const isAllShiped = arr.filter(el => el.status === 'shipped').length === arr.length;

	// 	return isAllShiped;
	// }

	return (
		<>
			{editRole &&
				<div className="action-btn action_btn_dot">

					<Dropdown>
						<Dropdown.Toggle variant="success" id="dropdown-basic">
							<i
								className="fa fa-ellipsis-v"
								style={{
									width: 35,
									fontSize: 20,
									padding: 11,
									color: '#e4566e',
								}}
							></i>
						</Dropdown.Toggle>

						<Dropdown.Menu>

							<Dropdown.Item className={row.status === 'denied' || !editRole ? 'disabled' : ''}>
								<div onClick={() => handleStatusChange('accepted', row._id, 'accept')}>
									Accept
								</div></Dropdown.Item>
							<Dropdown.Item className={row.status === 'denied' || !editRole ? 'disabled' : ''}>
								<div onClick={() => handleStatusChange('denied', row._id, 'deny')}>
									Deny
								</div></Dropdown.Item>
							<Dropdown.Item className={row.status === 'denied' || !editRole ? 'disabled' : ''}>
								<Link to={`/order-details?id=${row?._id}`}>
									Order Details
								</Link></Dropdown.Item>
							<Dropdown.Item className={row.status === 'denied' || !editRole ? 'disabled' : ''}>
								<div onClick={() => handleStatusChange('on hold', row._id, 'hold')}>
									Hold
								</div></Dropdown.Item>

							<Dropdown.Item className={(row.status === 'denied' || (row.status === 'shipped' && selectedlen === 0)) || !editRole ? 'disabled' : ''}>
								<div onClick={() => handleStatusChange('processed', row._id, 'process', row?.totalItems)}>
									Process
								</div>
							</Dropdown.Item>
							<Dropdown.Item className={row.status === 'denied' || !editRole ? 'disabled' : ''}>
								<Link to={`/invoice-details?id=${row?._id}`}>
									Get bill
								</Link>
								{/* <div onClick={() => saveDiv('pdf', 'Title')}>
							Get bill
						</div> */}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

				</div>
			}
		</>


		// <select value= {row.status} onChange={(e)=>handleStatusChange(e,row._id)}>
		// 	{/* <option value = ''>Choose Status</option> */}
		// 	<option value = 'pending'>Order Placed</option>
		// 	<option value = 'approved'>Order Approved</option>
		// 	<option value = 'delivered'>Order Delivered</option>
		// 	<option value = 'declined'>Order Cancelled</option>
		// 	<option value = 'shipped'>Order Shipped</option>
		// </select>
	)
};
export default ListDatatable;