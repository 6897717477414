import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { adminInstance } from '../../config/axios';
import { Button, Label, Form, FormGroup } from 'reactstrap';
import { getAdminData } from '../../reducers/adminReducers';

// import runpay from "../../assets/images/depositpopup/runpay.svg";
// import flouci from "../../assets/images/depositpopup/flouci.png";
// import gift from "../../assets/images/depositpopup/gift-voucher.png";
// import sobflous from "../../assets/images/depositpopup/sobflous.png";
// import swared from "../../assets/images/depositpopup/swared.png";
// import visa from "../../assets/images/depositpopup/visa.png";

const PaymentOption = () => {
	const initialChance = {
		// paymentOption: true,
		giftVoucher: true,
		visaMasterCard: true,
		runPay: true,
		flauci: true,
		sabflous: true,
		swared: true,
		edinar:true,
	};
	const dispatch = useDispatch();
	const result = useSelector((state) => state.admin);
	const { loginAdmin } = result || {};
	const { settings, role } = loginAdmin || {};
	const { giftVoucher, visaMasterCard, runPay, flauci, sabflous, swared,edinar } = settings || {};
	const [rollChance, setRollChance] = useState(initialChance);
	//const [tryForFree, setTryForFree] = useState(false);
	useEffect(() => {
		// if (paymentOption) {
		// 	console.log('paymentOption',paymentOption)
		//const copy = paymentOption;
		// console.log('paymentOption====112223', settings)
		setRollChance({ ...rollChance, giftVoucher, visaMasterCard, runPay, flauci, sabflous, swared,edinar });
		// }

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [giftVoucher, visaMasterCard, runPay, flauci, sabflous, swared, edinar]);

	// console.log('rollChance', rollChance)

	const onSubmitHandleData = async (e) => {
		e.preventDefault();
		try {
			const payload = {
				//paymentOption: rollChance?.paymentOption,
				giftVoucher: rollChance?.giftVoucher,
				visaMasterCard: rollChance?.visaMasterCard,
				runPay: rollChance?.runPay,
				flauci: rollChance?.flauci,
				sabflous: rollChance?.sabflous,
				swared: rollChance?.swared,
				edinar: rollChance?.edinar,
			};
			const response = await adminInstance().put(
				'/api/admin/updatePaymentOption',
				payload
			);
			const { code, msg } = response.data;
			if (code === 200) {
				toast.success(msg);
				dispatch(getAdminData());
			} else {
				toast.error(msg);
			}
		} catch (error) {
			console.log(error);
		}
	};

	const handleRollChanceVisibility = (e, p) => {
		const { name } = e.target;
		// console.log('name,', name, p);
		setRollChance({ ...rollChance, [name]: p });
	};
	// console.log('Roll Chance is here-->', rollChance);
	return (
		<>
			<Form className="needs-validation user-add" onSubmit={onSubmitHandleData}>
				{/* <FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="col-form-label pt-0">Payment Option</Label>
						<Label className="d-block hide_show">
						
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="paymentOption"
								defaultValue={rollChance.paymentOption || paymentOption}
								checked={rollChance?.paymentOption === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="paymentOption"
								checked={rollChance?.paymentOption === false ? true : false}
								defaultValue={rollChance?.paymentOption || paymentOption}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						
					</div>
				</FormGroup> */}
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						{/* <Label className="col-form-label pt-0">Payment Option</Label> */}
						<Label className="d-block hide_show">
							{/* <img src={gift} alt='payicon' /> */}
							<h4>Gift voucher</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="giftVoucher"
								defaultValue={rollChance.giftVoucher || giftVoucher}
								checked={rollChance?.giftVoucher === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="giftVoucher"
								checked={rollChance?.giftVoucher === false ? true : false}
								defaultValue={rollChance?.giftVoucher || giftVoucher}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						{/* <Label className="col-form-label pt-0">Payment Option</Label> */}
						<Label className="d-block hide_show">
							{/* <img src={gift} alt='payicon' /> */}
							<h4>Visa MasterCard</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="visaMasterCard"
								defaultValue={rollChance.visaMasterCard || visaMasterCard}
								checked={rollChance?.visaMasterCard === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="visaMasterCard"
								checked={rollChance?.visaMasterCard === false ? true : false}
								defaultValue={rollChance?.visaMasterCard || visaMasterCard}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						{/* <Label className="col-form-label pt-0">Payment Option</Label> */}
						<Label className="d-block hide_show">
							{/* <img src={gift} alt='payicon' /> */}
							<h4>Run Pay</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="runPay"
								defaultValue={rollChance.runPay || runPay}
								checked={rollChance?.runPay === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="runPay"
								checked={rollChance?.runPay === false ? true : false}
								defaultValue={rollChance?.runPay || runPay}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						{/* <Label className="col-form-label pt-0">Payment Option</Label> */}
						<Label className="d-block hide_show">
							{/* <img src={gift} alt='payicon' /> */}
							<h4>Flauci</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="flauci"
								defaultValue={rollChance.flauci || flauci}
								checked={rollChance?.flauci === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="flauci"
								checked={rollChance?.flauci === false ? true : false}
								defaultValue={rollChance?.flauci || flauci}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						{/* <Label className="col-form-label pt-0">Payment Option</Label> */}
						<Label className="d-block hide_show">
							{/* <img src={gift} alt='payicon' /> */}
							<h4>Sabflous</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="sabflous"
								defaultValue={rollChance.sabflous || sabflous}
								checked={rollChance?.sabflous === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="sabflous"
								checked={rollChance?.sabflous === false ? true : false}
								defaultValue={rollChance?.sabflous || sabflous}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						{/* <p className="text-danger">{emptyError || ''}</p> */}
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="d-block hide_show">
							<h4>Swared</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="swared"
								defaultValue={rollChance.swared || swared}
								checked={rollChance?.swared === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="swared"
								checked={rollChance?.swared === false ? true : false}
								defaultValue={rollChance?.swared || swared}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						
					
					</div>
				</FormGroup>
				<FormGroup className="row justify-content-start mx-0">
					<div className="admin-input col-xl-5 col-md-7">
						<Label className="d-block hide_show">
							<h4>Carte e-dinar</h4>
							<input
								className="radio_animated"
								id="edo-ani"
								type="radio"
								name="edinar"
								defaultValue={rollChance.edinar || edinar}
								checked={rollChance?.edinar === true ? true : false}
								onChange={(e) => handleRollChanceVisibility(e, true)}
							/>
							Enable
						</Label>
						<Label className="d-block hide_show">
							<input
								className="radio_animated"
								id="edo-ani1"
								type="radio"
								name="edinar"
								checked={rollChance?.edinar === false ? true : false}
								defaultValue={rollChance?.edinar || edinar}
								onChange={(e) => handleRollChanceVisibility(e, false)}
							/>
							Disable
						</Label>
						
					
					</div>
				</FormGroup>
				{role?.some((r) =>
					['suUsers', 'superAdmin', 'writeHideRolChance', 'writePreferences'].includes(r)
				) && (
						<div className="form-button d-flex justify-content-start pt-3">
							<Button type="submit" color="primary">
								Update
							</Button>
						</div>
					)}
			</Form>
		</>
	);
};

export default PaymentOption;
