import React, { Fragment, useState, useEffect } from 'react'
import { Tabs, TabPanel } from 'react-tabs' // TabList,  Tab
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yupCategoryValidation } from '../../utils/authValidation'
import { adminInstance } from '../../config/axios'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
// import placeholder from '../images/placeholder.png';
import '../../assets/styles/admin.css'
import { validateImage } from '../../utils/multiuseFunctions';

const AddCategoryForm = ({ payload, setPayload }) => {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // setValue,
  } = useForm({
    mode: 'onBlur',
    defaultValues: payload || {},
    resolver: yupResolver(yupCategoryValidation()),
  })

  const [editData, setEditData] = useState()
  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState(payload.image || '')
  const [catType, setCatType] = useState(payload?.categoryType)
  const [singleClick, setSingleClick] = useState(false);

  const onSubmitHandler = async (data) => {

    const formData = new FormData()
    setSingleClick(true)
    formData.append('file', data?.logo[0])
    delete data.logo
    if (catType === 'box') {
      delete data?.dontShowInStore
    }
    Object.keys(data).map((el) => formData.append(el, data[el]))
    try {
      const { _id } = payload || {}
      console.log('data', data)
      if (Object.keys(errors).length === 0) {
        if (_id) {
          const response = await adminInstance().put(
            `/api/admin/updateCategory/${_id}`,
            formData
          )
          const { code, msg } = response.data

          setEditData({ ...editData, isUpdate: true })
          if (code === 200) {
            toast.success(msg, {
              toastId: 1,
            })
            reset()
            history.push('/category-list')
          } else {
            toast.error(msg, {
              toastId: 1,
            })
          }
        } else {
          const response = await adminInstance().post(
            '/api/admin/createCategory',
            formData
          )
          const { code, msg } = response.data
          if (code === 200) {
            reset()
            toast.success(msg, {
              toastId: 1,
            })
            history.push('/category-list')
          } else {
            toast.error(msg, {
              toastId: 1,
            })
          }
        }
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    if (!selectedFile) {
      setPreview(payload.image || '')
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);
  
  const onSelectFile = async (e) => {
		try{
			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined);
				return;
			}
			await validateImage(e.target.files[0]); 
			setSelectedFile(e.target.files[0]);
		}catch(error){
		const { msg, valid} = error || {}
			if(!valid){
				toast.error(msg);
			}
		}
	};
  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className='needs-validation user-add admin-type '
            onSubmit={handleSubmit(onSubmitHandler)}>
            {/* <h4>Add Category</h4> */}
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Name
                </Label>
                <input
                  {...register('name')}
                  className='form-control'
                  type='text'
                  defaultValue={payload.name || ''}
                />

                <p className='text-danger'>{errors.name?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-intype col-xl-5 col-md-7'>
                <Label for='chooseFile' className='col-form-label pt-0'>
                  <span></span> Image
                </Label>
                <div className='file-upload'>
                  <div className='file-select'>
                    <div className='file-select-button' id='fileName'>
                      Choose File
                    </div>
                    <div className='file-select-name' id='noFile'>
                      No file chosen...
                    </div>
                    <input
                      id='chooseFile'
                      name='chooseFile'
                      {...register('logo')}
                      type='file'
                      accept='image/png, image/gif, image/jpeg'
                      onChange={onSelectFile}
                      defaultValue={payload.Image || ''}
                    />
                  </div>
                  {selectedFile && (
                    <img style={{ width: '40%' }} src={preview} alt='as' />
                  )}
                </div>
              </div>
            </FormGroup>

            <FormGroup>
              { }
              <Label className='col-form-label'>Category Type</Label>
              <div className='m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated'>
                <Label className='d-block'>
                  <Label className='col-form-label'>Box</Label>
                  <input
                    {...register('categoryType')}
                    type='radio'
                    value='box'
                    onClick={() => setCatType('box')}
                  // checked={payload.categoryType === 'box' ? true : false}
                  />
                  <p className='text-danger'>{errors.categoryType?.message}</p>
                  Active
                </Label>
                <Label className='d-block'>
                  <Label className='col-form-label'>Product</Label>
                  <input
                    {...register('categoryType')}
                    type='radio'
                    value='product'
                    defaultChecked
                    onClick={() => setCatType('product')}
                  // checked={payload.categoryType === 'product' ? true : false}
                  />
                  <p className='text-danger'>{errors.categoryType?.message}</p>
                  Deactive
                </Label>
              </div>
            </FormGroup>
            {console.log('editData', catType)}
            {catType === 'product' && <FormGroup>
              <Label className="d-block track-input track-inp">
                <input
                  type="checkbox"
                  {...register('dontShowInStore')}
                // name="checkTrack"

                // checked={payload.checkTrack}
                // value={payload.checkTrack}
                // onChange={(e) =>
                // 	handleOnChange(e.target, payload.checkTrack)
                // }
                />
                Don't show in store
              </Label>
            </FormGroup>}

            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-intype col-xl-5 col-md-7 '>
                <Label className='col-form-label pt-0'>
                  <span></span> Description{' '}
                </Label>
                <textarea
                  rows='4'
                  cols='12'
                  {...register('description')}
                  className='form-control'
                  type='text'
                  defaultValue={payload.description || ''}
                />
              </div>
            </FormGroup>
            <div className='form-button d-flex justify-content-start pt-3'>
              <Button color='primary' type='submit' disabled={singleClick}>
                Submit
              </Button>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  )
}
export default AddCategoryForm
