import React from "react";
import { useState, useEffect } from "react";
import { Button, Label, Form, FormGroup } from "reactstrap";
import { adminInstance } from "../../config/axios";
import { toast } from "react-toastify";
import { getAllAffiliateLevels } from "./../../reducers/adminReducers";
import { useDispatch } from "react-redux";
function Affiliates({ userData, userId }) {
  const { disableStatus, affiliateLevel } = userData || {};
  console.log("userData ==>", userData);

  const dispatch = useDispatch();

  const [affiliate, setAffiliate] = useState(disableStatus);
  const [allAffiliateLevels, setAllAffiliateLevels] = useState([]);
  const [levelId, setLevelId] = useState(affiliateLevel);

  const allLevelsAndUser = async () => {
    let allLevel = await dispatch(getAllAffiliateLevels());
    allLevel = allLevel.payload.resultData.data.allAffiliateLevels;
    setAllAffiliateLevels([...allLevel]);
  };

  useEffect(() => {
    allLevelsAndUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("thius is my affiliate level ", userDetails.affiliateLevel);

  const onSubmitHandleData = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        disableAffiliate: affiliate,
        levelId: levelId || "",
        userId: userId,
      };
      const response = await adminInstance().put(
        "/api/admin/updateDisableAffiliate",
        payload
      );
      const { code, msg } = response.data;
      if (code === 200) {
        toast.success(msg);
      } else {
        toast.error(msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAffiliateVisibility = (value) => {
    setAffiliate(value);
  };

  const handleLevel = (value) => {
    setLevelId(value);
  };
  console.log("allAffiliateLevels ===>", allAffiliateLevels, levelId);
  return (
    <div>
      <Form className="needs-validation user-add" onSubmit={onSubmitHandleData}>
        <FormGroup className="row justify-content-start mx-0">
          <div className="admin-input col-xl-5 col-md-7">
            <Label className="col-form-label pt-0">
              Disable/Enable Affiliate
            </Label>
            <Label className="d-block hide_show">
              <input
                className="radio_animated"
                id="edo-ani"
                type="radio"
                name="disableAffiliate"
                checked={!affiliate}
                onChange={(e) => handleAffiliateVisibility(false)}
              />
              Enable
            </Label>
            <Label className="d-block hide_show">
              <input
                className="radio_animated"
                id="edo-ani1"
                type="radio"
                name="disableAffiliate"
                checked={affiliate}
                onChange={(e) => handleAffiliateVisibility(true)}
              />
              Disable
            </Label>
          </div>
        </FormGroup>

        <select
          class="form-control"
          defaultValue="Select Level Name"
          name="level"
          onChange={(e) => handleLevel(e?.target?.value)}
        >
          {allAffiliateLevels.map((level) => (
            <option value={level?._id} selected={level?._id === levelId}>
              {level?.levelName}
            </option>
          ))}
        </select>

        <div className="form-button d-flex justify-content-start pt-3">
          <Button type="submit" color="primary">
            Update
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default Affiliates;
