import React, { Fragment, useState, useEffect } from 'react'
import { Tabs, TabPanel } from 'react-tabs' // TabList,  Tab
import { Button, Form, FormGroup, Label, } from 'reactstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { yupCategoryValidation } from '../../utils/authValidation'
import { adminInstance, } from '../../config/axios'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import '../../assets/styles/admin.css'
import { validateImage } from '../../utils/multiuseFunctions';

const AddBrandForm = ({ payload }) => {
  //{payload}
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    defaultValues: payload || {},
    resolver: yupResolver(yupCategoryValidation()),
  });

  const [editData, setEditData] = useState();

  const onSubmitHandler = async (data) => {
    const formData = new FormData();
    setSingleClick(true)
    formData.append("file", data?.logo[0]);
    delete data.logo;
    Object.keys(data).map((el) => formData.append(el, data[el]));
    try {
      const { _id } = payload || {};
      if (Object.keys(errors).length === 0) {
        if (_id) {
          const response = await adminInstance().put(
            `/api/admin/updateBrand/${_id}`,
            formData
          );
          const { code, msg } = response.data;
          setEditData({ ...editData, isUpdate: true });
          if (code === 200) {
            toast.success(msg, { toastId: 1 });
            reset();
            history.push("/brand-list");
          } else {
            toast.error(msg, { toastId: 1 });
          }
        } else {
          // console.log('i am in add condition')
          const response = await adminInstance().post(
            "/api/admin/createBrand",
            formData
          );
          const { code, msg } = response.data;
          if (code === 200) {
            reset();
            toast.success(msg, { toastId: 1 });
            history.push("/brand-list");
          } else {
            toast.error(msg, { toastId: 1 });
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }


  const [selectedFile, setSelectedFile] = useState()
  const [preview, setPreview] = useState(payload.image || '')
  const [singleClick, setSingleClick] = useState(false);


  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(payload.image || '')
      return
    }
    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile])

  const onSelectFile = async (e) => {
		try{
			if (!e.target.files || e.target.files.length === 0) {
				setSelectedFile(undefined);
				return;
			}
			await validateImage(e.target.files[0]); 
			setSelectedFile(e.target.files[0]);
		}catch(error){
		const { msg, valid} = error || {}
			if(!valid){
				toast.error(msg);
			}
		}
	};
  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className="needs-validation user-add"
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            {/* <h4>Add Category</h4> */}
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span>*</span> Name
                </Label>
                <input
                  {...register("name")}
                  className="form-control"
                  type="text"
                  defaultValue={payload.name || ""}
                />
                <p className="text-danger">{errors.name?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span></span> Image
                </Label>
                <div className="file-upload">
                  <div className="file-select">
                    <div className="file-select-button" id="fileName">
                      Choose File
                    </div>
                    <div className="file-select-name" id="noFile">
                      No file chosen...
                    </div>
                    <input
                      {...register("logo")}
                      className="form-control"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={onSelectFile}
                      defaultValue={payload.Image || ""}

                    />
                  </div>
                  {selectedFile && (
                    <img
                      style={{ width: "40%", height: "inherit" }}
                      src={preview}
                      alt="as"
                    />
                  )}
                  <p className="text-danger err-message">
                    {errors.Image?.message}
                  </p>
                </div>
              </div>
            </FormGroup>
            <FormGroup className="row justify-content-start mx-0">
              <div className="admin-input col-xl-5 col-md-7">
                <Label className="col-form-label pt-0">
                  <span></span> Description{" "}
                </Label>
                <textarea rows="4" cols="12"
                  {...register('description')}
                  className='form-control'
                  type='text'
                  defaultValue={payload.description || ''}
                />
              </div>
            </FormGroup>
            <div className="form-button d-flex justify-content-start pt-3">
              <Button color="primary" type="submit" disabled={singleClick}>
                Submit
              </Button>
            </div>
          </Form>
        </TabPanel>
      </Tabs>
    </Fragment>
  );
};
export default AddBrandForm;
