import React, { Fragment, useState } from 'react';
import { Tabs, TabPanel } from 'react-tabs';
import { Button, Form, FormGroup, Col, Row } from 'reactstrap';
import { adminInstance } from '../../config/axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import defaultproductimg from '../../assets/images/product-list/1.jpg';
import ReactPaginate from 'react-paginate';
import { getBoxPrice, getClientSeed } from '../../utils/multiuseFunctions';
const AddPlayBox = ({
	payload,
	products,
	productList,
	categoryList,
	handleSearchProduct,
	rarityList,
	handlePageClick,
	pageCount,
	handleProduct,
	handleOnchangePro,
	chooseProduct,
	handleRemove,
	bxPrice,
	boxCategoryList,
	totalOdds,
}) => {
	//const result = useSelector((state) => state.admin)
	//const { loginAdmin } = result || {}
	//const { settings } = loginAdmin || {}
	// const { boxProfit } = settings || {}
	// const { boxProfit } = loginAdmin?.settings;

	const history = useHistory();
	const clientSeed = getClientSeed();
	const [checkStatus, setCheckStatus] = useState(false);
	const onSubmitHandler = async (e) => {
		try {
			e.preventDefault();

			if (products && products?.length > 0) {
				const everychance = chooseProduct.every(
					(el) => parseInt(el.playNumber) > 0
				);
				if (everychance) {
					setCheckStatus(true);
					const payLoad = {
						playCount: chooseProduct,
						products,
						type: 'bot',
						randomNumber: clientSeed,
					};
					//console.log('payLoad==>', payLoad);

					const response = await adminInstance().post(
						'/api/admin/playBotGame',
						payLoad
					);
					const { code, msg } = response.data;
					if (code === 200) {
						toast.success(msg);
						setCheckStatus(false);
						history.push('/bot-simulator');
					} else {
						toast.error(msg);
					}
				} else {
					toast.error('Please add pay number');
				}
			} else {
				toast.error('Please select box');
			}
		} catch (error) {
			console.log(error);
		}
	};
	//console.log('box profite is here->', boxProfit)
	return (
		<Fragment className="addbox-form">
			<Tabs>
				<TabPanel>
					<Form
						className="needs-validation user-add"
						onSubmit={onSubmitHandler}
					>
						<Row>
							<Col xl="12">
								<FormGroup className="search_boxes_section search-box-section justify-content-center">
									<div className="row">
										{/* <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Search Here
                      </Label>
                      <input
                        type='text'
                        placeholder='Search'
                        name='title'
                        onChange={(e) => handleSearchProduct(e)}
                        class='form-control'></input>
                      <i class='fa fa-search search-icon'></i>
                    </div>
                    <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Min
                      </Label>
                      <i class='fa fa-dollar dollar-icon'></i>
                      <input
                        name='min'
                        onChange={(e) => handleSearchProduct(e)}
                        className='form-control'
                        type='number'
                        placeholder='Min'
                      />
                    </div>

                    <div className='addbox-input col-xl-4 col-md-4'>
                      <Label className='col-form-label pt-0'>
                        <span></span> Max
                      </Label>
                      <i class='fa fa-dollar dollar-icon'></i>
                      <input
                        name='max'
                        onChange={(e) => handleSearchProduct(e)}
                        className='form-control'
                        type='number'
                        placeholder='Max'
                      />
                    </div> */}

										<div className="addbox-input col-xl-12 col-md-12">
											<div className="search-section">
												{/* <div className='search-category'>
                          <select
                            class='category-search'
                            name='category'
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=''> Choose Category</option>
                            {categoryList &&
                              categoryList.length > 0 &&
                              categoryList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                          <select
                            class='category-search'
                            name='rarity'
                            onChange={(e) => handleSearchProduct(e)}>
                            <option value=''> Choose Rarity</option>
                            {rarityList &&
                              rarityList.length > 0 &&
                              rarityList.map((el) => (
                                <option value={el.value}>{el.label}</option>
                              ))}
                          </select>
                        </div> */}

												<div className="searched-content-section">
													<div class="row">
														{productList.map((el) => {
															const prices = getBoxPrice(
																el?.boxItems,
																el?.profitMargin
															);
															return (
																<>
																	{parseFloat(prices) > 0 ? (
																		<div className="col-md-2">
																			<div className="search-list">
																				<div
																					className="middle-btn-selected"
																					onClick={() =>
																						handleProduct(el, prices)
																					}
																				>
																					<span>Selected</span>
																				</div>
																				<img
																					src={
																						el.image
																							? el.image
																							: defaultproductimg
																					}
																					alt="No ImGE fOUND"
																				/>
																				<h3 className="search-title">
																					{el.name}
																				</h3>
																				<p className="search-price">
																					{prices} DT
																				</p>
																			</div>
																		</div>
																	) : (
																		''
																	)}
																</>
															);
														})}
													</div>
													<div class="row">
														<div className="col-xl-12">
															<div class="text-center search-pagination">
																<ReactPaginate
																	breakLabel="..."
																	nextLabel=""
																	onPageChange={handlePageClick}
																	pageRangeDisplayed={10}
																	pageCount={pageCount}
																	previousLabel=""
																	renderOnZeroPageCount={null}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</FormGroup>
								<FormGroup className="row justify-content-center">
									{products &&
										products.length > 0 &&
										products.map((el, index) => {
											const { val, price } = el || {};
											const { image, name } = val || {};
											return (
												<div className="addbox-input col-xl-12 col-md-12">
													<div className="search-section search__section">
														<div className="choose-odds">
															<div className="row">
																<div class="col-md-6">
																	<div className="choose-odds-content">
																		<div className="odds-icon">
																			<img
																				src={image || defaultproductimg}
																				alt=""
																				hight="50"
																				width="50"
																			/>
																		</div>

																		<div>
																			<h5>{name || ''}</h5>
																		</div>
																	</div>
																</div>

																<div class="col-md-6">
																	<div className="choose-odds-content">
																		<div className="price">
																			<span>{price} DT</span>
																		</div>
																		<div className="play__num">
																			<span>Play Number:</span>

																			<input
																				type="number"
																				name="playNumber"
																				onChange={(e) =>
																					handleOnchangePro(e.target, index)
																				}
																				value={chooseProduct?.playNumber}
																				placeholder="0.00"
																			/>
																		</div>
																		<div>
																			{/* <input
                                      type='number'
                                      name='chance'
                                      onChange={(e) =>
                                        handleOnchangePro(e.target, index)
                                      }
                                      value={chooseProduct[index].chance || ''}
                                      placeholder='0'
                                    /> */}
																			<Button
																				className="del-icon"
																				onClick={() => handleRemove(index)}
																			>
																				<i class="fa fa-trash"></i>
																			</Button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											);
										})}
								</FormGroup>
								<div className="form-button d-flex submit-btns pt-3">
									<div className="submit-btns-inner">
										{/* <Button color='primary'>
                    Total Box Price :{' '}
                    <span>{`$ ${payload?.boxPrice || bxPrice}`}</span>
                  </Button>
                  <Button color='primary'>
                    Total odds :{' '}
                    <span>{`$ ${totalOdds || 0}`} %</span>
                  </Button> */}
									</div>
									{/* {checkStatus && (
                      <div class="spinner-border text-danger" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                   )} */}

									<Button
										color="primary"
										type="submit"
										disabled={checkStatus ? true : false}
									>
										Play
									</Button>
								</div>
							</Col>
						</Row>
					</Form>
				</TabPanel>
			</Tabs>
		</Fragment>
	);
};
export default AddPlayBox;
