import React, { Fragment, useState } from 'react'
import { Tabs, TabPanel } from 'react-tabs' // TabList,  Tab
import { Button, Form, FormGroup, Label } from 'reactstrap' // Col, Input, Row
import { yupCreateUserValidation } from '../../utils/authValidation'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify' // ToastContainer
import { adminInstance } from '../../config/axios'
import { useHistory } from 'react-router-dom'

const TabsetUser = ({ payload }) => {
  const governoratesData = [
    'Ariana',
    'Béja',
    'Ben Arous',
    'Bizerte',
    'Gabès',
    'Gafsa',
    'Jendouba',
    'Kairouan',
    'Kasserine',
    'Kebili',
    'Kef',
    'Mahdia',
    'Manouba',
    'Medenine',
    'Monastir',
    'Nabeul',
    'Sfax',
    'Sidi Bouzid',
    'Siliana',
    'Sousse',
    'Tataouine',
    'Tozeur',
    'Tunis',
    'Zaghouan',
  ]
  const { _id } = payload || {}
  let history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: payload || {},
    resolver: yupResolver(yupCreateUserValidation(_id)),
  })

  const [editData, setEditData] = useState()

  const onSubmitHandler = async (data) => {
    try {
      if (Object.keys(errors).length === 0) {
        if (_id) {
          const response = await adminInstance().put(
            `/api/admin/editUserData/${_id}`,
            data
          )
          // console.log('in user edit condition')
          const { code, msg } = response.data
          setEditData({ ...editData, isUpdate: true })
          if (code === 200) {
            toast.success(msg)
            reset()
            history.push('/users/list-user')
          } else {
            toast.error(msg)
          }
        } else {
          const response = await adminInstance().post(
            '/api/admin/registerUser',
            data
          )
          // console.log('in user add condition');
          const { code, msg } = response.data
          if (code === 200) {
            toast.success(msg)
            reset()
            history.push('/users/list-user')
          } else {
            toast.error(msg)
          }
        }
      } else {
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Fragment>
      <Tabs>
        <TabPanel>
          <Form
            className='needs-validation user-add admin-card'
            onSubmit={handleSubmit(onSubmitHandler)}>
            <h4>Account Details</h4>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Username
                </Label>
                <input
                  {...register('username')}
                  className='form-control tolower'
                  placeholder='User name'
                  type='text'
                  defaultValue={payload.username || ''}
                  disabled={_id ? true : false}
                />
                <p className='text-danger'>{errors.username?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> First Name
                </Label>
                <input
                  {...register('firstname')}
                  className='form-control'
                  placeholder='First Name'
                  type='text'
                  defaultValue={payload.firstname || ''}
                />
                <p className='text-danger'>{errors.firstname?.message}</p>
                {/* <Input
								className="form-control col-xl-8 col-md-7"
								id="validationCustom0"
								type="text"
								required=""
							/> */}
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Last Name
                </Label>
                <input
                  {...register('lastname')}
                  className='form-control'
                  placeholder='Last Name'
                  type='text'
                  defaultValue={payload.lastname || ''}
                />
                <p className='text-danger'>{errors.lastname?.message}</p>
              </div>
              {/* <Input
								className="form-control col-xl-8 col-md-7"
								id="validationCustom1"
								type="text"
								required=""
							/> */}
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Email
                </Label>
                <input
                  {...register('email')}
                  className='form-control'
                  placeholder='Email'
                  type='text'
                  defaultValue={payload.email || ''}
                />
                <p className='text-danger'>{errors.email?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <Label className='col-form-label pt-0'>
                  <span>*</span> Phone
                </Label>
                <input
                  {...register('mobile')}
                  className='form-control'
                  placeholder='Phone Number'
                  type='text'
                  defaultValue={payload.mobile || ''}
                />
                <p className='text-danger'>{errors.mobile?.message}</p>
              </div>
            </FormGroup>
            <FormGroup className='row justify-content-start mx-0'>
              <div className='admin-input col-xl-5 col-md-7'>
                <div>
                  <Label className='col-form-label pt-0'>
                    Governorate
                  </Label>
                  <select
                    class='form-control'
                    {...register('governorate')}
                    defaultValue={payload.governorate || ''}
                  >
                    <option value=''> Choose Governorate</option>
                    {governoratesData &&
                      governoratesData.length > 0 &&
                      governoratesData.map((el) => (
                        <option value={el} selected={el === payload.governorate ? true : false}> {el}</option>
                      ))}
                  </select>

                </div>
              </div>
            </FormGroup>
            <div className='pull-right d-flex justify-content-start pt-3'>
              <Button type='submit' color='primary'>
                Submit
              </Button>
            </div>
          </Form>
        </TabPanel>
        <TabPanel></TabPanel>
      </Tabs>
    </Fragment>
  )
}

export default TabsetUser
